import { DailyAudio, DailyVideo, useParticipantProperty } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { ReactComponent as CamOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import styles from "./DailySpotlighted.module.css";

const DailySpotlighted = ({ session_id, isLocal }) => {

    const [participant, setParticipant] = useState(null)
    const participantProprerty = useParticipantProperty(session_id, ['userData', 'tracks', 'user_name', 'owner'])
    const [selfViewExpanded, setSelfViewExpaned] = useState(false)
    const [showSelfView, setShowSelfView] = useState(false)

    useEffect(() => {
        if (participantProprerty) {
            try {
                let participant = {
                    id: session_id,
                    data: participantProprerty[0],
                    tracks: participantProprerty[1],
                    video: participantProprerty[1].video.state === 'playable',
                    audio: participantProprerty[1].audio.state === 'playable',
                    screenVideo: participantProprerty[1].screenVideo.state === 'playable',
                    name: participantProprerty[2],
                    owner: participantProprerty[3],
                    isLocal: isLocal
                }
                setParticipant(participant)
            }
            catch (error) {
                console.log(error)
            }
        }
    }, [participantProprerty])

    useEffect(() => {
        if (participant) {
            if(participant.screenVideo){
                setShowSelfView(true)
            }
            else{
                setShowSelfView(false)
            }
        }
    }, [participant])

    return (
        <>
            {participant &&
                <div className={styles.container} 
                style={{ backgroundColor: participant.data?.color && (!participant.video && !participant.screenVideo) ? (participant.data?.color ?? 'black') : 'black' }}>
                    {(participant.video || participant.screenVideo) &&
                        <DailyVideo
                            automirror={participant.isLocal}
                            type={participant.screenVideo ? 'screenVideo' : 'video'}
                            sessionId={session_id}
                            style={{ display: 'flex', objectFit: 'contain', width: '100%', height: '100%' }} />
                    }
                    {!participant.video && !participant.screenVideo &&
                        <CamOffIcon className={styles.cameraOff}/>
                    }
                    {/* {!participant.isLocal &&
                        <DailyAudio sessionId={session_id} />
                    } */}

                    {participant.data && !showSelfView &&
                        <div className={`${styles.participantName} ${participant.screenVideo ? styles.screenshare : ''}`}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `${participant.data?.name} ${participant.data?.surname}`}`}</div>
                    }

                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                    {participant.screenVideo && participant.video &&
                        <>
                            {!selfViewExpanded &&
                                <div className={`${styles.userTip} ${showSelfView ? styles.open : ''}`} onClick={() => {
                                    setShowSelfView(!showSelfView)
                                }}>
                                    <ChevronIcon className={`${styles.chevron} ${showSelfView ? styles.open : ''}`} />
                                </div>
                            }
                            <div className={`${styles.selfViewWrapper} ${selfViewExpanded ? styles.expanded : ''} ${showSelfView ? styles.open : ''}`}>
                                <div className={`${styles.selfView} ${selfViewExpanded ? styles.expanded : ''}`}
                                    onClick={() => {
                                        setSelfViewExpaned(true)
                                    }}>
                                    <DailyVideo
                                        automirror={participant.isLocal}
                                        type={'video'}
                                        sessionId={session_id}
                                        style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                                </div>
                                <div className={styles.name}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `${participant.data?.name} ${participant.data?.surname}`}`}</div>
                                {selfViewExpanded &&
                                    <div
                                        className={styles.closeSelfView}
                                        onClick={() => {
                                            setSelfViewExpaned(false)
                                        }}>
                                        <CloseIcon />
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            }
        </>
    )
}

export default DailySpotlighted