import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import styles from "./PDFCanvas.module.css"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import { ReactComponent as CursorIcon } from "../../assets/images/icons/ic-cursor.svg"
import { ReactComponent as MoveIcon } from "../../assets/images/icons/ic-move.svg"
import { ReactComponent as ZoomInIcon } from "../../assets/images/icons/ic-zoom-in.svg"
import { ReactComponent as ZoomOutIcon } from "../../assets/images/icons/ic-zoom-out.svg"

const PDFCanvas = ({ file }) => {
    const containerRef = useRef(null)
    const canvasRef = useRef(null);
    const [ctx, setCtx] = useState(null)
    const [pdf, setPdf] = useState(null);
    const [pageNumber, _setPageNumber] = useState(1);
    const [numPages, _setNumPages] = useState(null);
    const [renderTask, setRenderTask] = useState(null)
    const [scale, setScale] = useState(1)
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isPanning, setIsPanning] = useState(false);
    const [pos, setPos] = useState({ dx: null, dy: null }) // Modifica qui
    const [firstRender, setFirstRender] = useState(true)
    const [canvasCenter, setCanvasCenter] = useState({ dx: null, dy: null })

    const [selectedTool, setSelectedTool] = useState('default')

    let MAX_ZOOM = 5
    let MIN_ZOOM = 0.1
    let SCROLL_SENSITIVITY = 0.0005

    const pageNumberRef = useRef(pageNumber);
    const setPageNumber = data => {
        pageNumberRef.current = data;
        _setPageNumber(data);
    };

    const numPagesRef = useRef(pageNumber);
    const setNumPages = data => {
        numPagesRef.current = data;
        _setNumPages(data);
    };

    useEffect(() => {
        if (selectedTool === 'default') {
            if (containerRef.current) {
                containerRef.current.style.cursor = 'default'
            }
        }
        if (selectedTool === 'grab') {
            if (containerRef.current) {
                containerRef.current.style.cursor = 'grab'
            }
        }
    }, [selectedTool])

    useEffect(() => {

        const handleKeyDown = (event) => {
            switch (event.key) {

                case 'ArrowLeft':
                    handlePreviousPage()
                    // Aggiungi qui la logica da eseguire quando la freccia sinistra viene premuta
                    break;
                case 'ArrowRight':
                    handleNextPage()
                    // Aggiungi qui la logica da eseguire quando la freccia destra viene premuta
                    break;
                default:
                    break;
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver((e) => {
            setFirstRender(true)
            setPos(canvasCenter)
        });
        resizeObserver.observe(containerRef.current);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            resizeObserver.disconnect()
        };
    }, []);

    useEffect(() => {
        const loadPdf = async () => {
            if (file) {
                try {
                    const fileReader = new FileReader();
                    fileReader.onload = async () => {
                        const typedArray = new Uint8Array(fileReader.result);
                        const loadingTask = pdfjsLib.getDocument(typedArray);
                        const pdf = await loadingTask.promise;
                        setPdf(pdf);
                        setNumPages(pdf.numPages);
                        setPageNumber(1); // reset to first page when a new file is loaded
                    };
                    fileReader.readAsArrayBuffer(file);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            }
        };

        loadPdf();
    }, [file]);

    const renderPage = async (num) => {
        if (pdf) {
            try {
                const page = await pdf.getPage(num);
                const viewport = page.getViewport({ scale: 1 }); // Ottieni la viewport con scala 1
                const container = containerRef.current;
                const canvas = canvasRef.current;
                const context = ctx ?? canvas.getContext('2d');
                if (!ctx) {
                    setCtx(context)
                }

                // Imposta le dimensioni del canvas per corrispondere a quelle del container
                canvas.width = container.offsetWidth;
                canvas.height = container.offsetHeight;

                var currentScale = scale
                if (firstRender) {
                    currentScale = Math.min(
                        container.offsetHeight / viewport.height,
                        container.offsetWidth / viewport.width
                    );
                    setScale(currentScale);
                    setFirstRender(false);
                }

                var scaledViewport = page.getViewport({ scale: currentScale }); // Ottieni la viewport con la nuova scala

                var dx = (canvas.width - scaledViewport.width) / 2;
                var dy = (canvas.height - scaledViewport.height) / 2;

                if (!canvasCenter) {
                    setCanvasCenter({ dx: dx, dy: dy })
                }

                if (!pos.dx && !pos.dy) {
                    setPos({
                        dx: dx,
                        dy: dy
                    })
                }
                else {
                    dx = pos.dx
                    dy = pos.dy
                }

                const renderContext = {
                    canvasContext: context,
                    viewport: scaledViewport,
                    transform: [1, 0, 0, 1, dx, dy] // Aggiungi un traslato qui
                };

                try {
                    if (renderTask) {
                        await renderTask.cancel();
                    }

                    const rt = page.render(renderContext);
                    setRenderTask(rt)
                    await rt.promise;

                }
                catch (e) {
                    console.error(e)
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    };

    useEffect(() => {

        renderPage(pageNumberRef.current);

    }, [pdf, pageNumber, pos, scale]);

    const handlePreviousPage = () => {
        if (pageNumberRef.current - 1 > 0) {
            setPageNumber(pageNumberRef.current - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumberRef.current + 1 <= numPagesRef.current) {
            setPageNumber(pageNumberRef.current + 1);
        }
    };

    const onWheel = (e) => {
        if (selectedTool === 'grab') {
            e.stopPropagation()
            e.preventDefault()

            // Calcola la posizione del mouse rispetto al canvas
            const rect = canvasRef.current.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            const mouseY = e.clientY - rect.top;

            // Calcola la posizione del mouse rispetto al documento PDF
            const pdfX = (mouseX - pos.dx) / scale;
            const pdfY = (mouseY - pos.dy) / scale;

            // Calcola la nuova scala in base alla direzione dello scrolling
            const zoomLevel = e.deltaY * SCROLL_SENSITIVITY
            const newScale = scale - zoomLevel

            if (newScale <= MAX_ZOOM && newScale >= MIN_ZOOM) {
                // Calcola la nuova posizione del canvas in modo che il punto sotto il cursore del mouse rimanga fisso
                const newDx = mouseX - pdfX * newScale;
                const newDy = mouseY - pdfY * newScale;

                // Aggiorna la posizione del canvas e la scala
                setPos({ dx: newDx, dy: newDy });
                setScale(newScale);
            }
        }
    }

    const onMouseEnter = (e) => {
        if (selectedTool === 'grab') {
            document.body.style.overflow = 'hidden'
        }
    }

    const onMouseLeave = (e) => {
        document.body.style.overflow = ''
    }

    const onMouseDown = (e) => {
        if (selectedTool === 'grab') {
            setIsPanning(true);
            setMousePosition({ x: e.clientX, y: e.clientY });
            containerRef.current.style.cursor = 'grabbing'
        }
    };

    const onMouseMove = (e) => {
        if (selectedTool === 'grab') {

            if (isPanning) {
                const dx = e.clientX - mousePosition.x;
                const dy = e.clientY - mousePosition.y;

                // Aggiorna la posizione del canvas
                setPos(prevPos => ({
                    dx: prevPos.dx + dx,
                    dy: prevPos.dy + dy
                }));


                // Aggiorna la posizione del mouse
                setMousePosition({ x: e.clientX, y: e.clientY });
            }
        }
    };

    const onMouseUp = () => {
        if (selectedTool === 'grab') {
            setIsPanning(false);
            containerRef.current.style.cursor = 'grab'
        }
    };

    return (
        <div
            ref={containerRef}
            className={styles.container}
            onWheel={onWheel}
            onMouseOver={onMouseEnter}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
        >
            <canvas id="presentation" ref={canvasRef} className={styles.pdfCanvas} />
            {/* style={{ top: pos ? pos.top : 'unset', left: pos ? pos.left : 'unset' }} /> */}
            {pageNumber > 1 &&
                <button className={`${styles.arrow} ${styles.left}`} onClick={handlePreviousPage} disabled={pageNumber <= 1}>
                    <ChevronIcon />
                </button>
            }
            {pageNumber < numPagesRef.current &&
                <button className={`${styles.arrow} ${styles.right}`} onClick={handleNextPage} disabled={pageNumber >= numPages}>
                    <ChevronIcon />
                </button>
            }
            <div className={styles.toolbar}>
                <div className={`${styles.tool} ${selectedTool === 'default' ? styles.active : ''}`}
                    onClick={() => { setSelectedTool('default') }}
                >
                    <CursorIcon />
                </div>
                <div className={`${styles.tool} ${selectedTool === 'grab' ? styles.active : ''}`}
                    onClick={() => { setSelectedTool('grab') }}
                >
                    <MoveIcon />
                </div>
                <div className={`${styles.tool}`}
                    onClick={() => {
                        if (scale - 0.1 > MIN_ZOOM) {
                            setScale(scale - 0.1)
                            setPos(canvasCenter)
                        }
                    }}
                >
                    <ZoomOutIcon />
                </div>
                <div className={`${styles.tool}`}
                    onClick={() => {
                        if (scale + 0.1 < MAX_ZOOM) {
                            setScale(scale + 0.1)
                            setPos(canvasCenter)
                        }
                    }}
                >
                    <ZoomInIcon />
                </div>
            </div>

            <div className={styles.pageNumber}>
                {pageNumberRef.current}/{numPagesRef.current}
            </div>
        </div>
    );
};

export default PDFCanvas;
