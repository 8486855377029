import styles from "./AudioSlider.module.css";
import { ReactComponent as SpeakerIcon } from "../../assets/images/icons/ic-speaker.svg"
import { ReactComponent as SpeakerIcon0 } from "../../assets/images/icons/ic-speaker0.svg"
import { ReactComponent as SpeakerIcon1 } from "../../assets/images/icons/ic-speaker1.svg"
import { useEffect, useState } from "react";

const AudioSlider = ({level, onChange = () => {}}) => {

    const [icon, setIcon] = useState(<SpeakerIcon className={styles.volume}/>)

    const handleChange = (event) => {
        let value = event.target.value        
        onChange(value)
    }

    useEffect(() => {
        if(level < 1){
            setIcon(<SpeakerIcon0 className={styles.volume}/>)
        }
        if(level > 0 && level < 50){
            setIcon(<SpeakerIcon1 className={styles.volume}/>)
        }
        if(level > 50){
            setIcon(<SpeakerIcon className={styles.volume}/>)
        }
    }, [level])

    return (
        <label className={styles.slider}>
            <input type="range" className={styles.level} onChange={handleChange} value={level}/>
                {icon}
        </label>

    )

}

export default AudioSlider