import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise-inactive.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as PencilIcon } from "../../assets/images/icons/ic-pencil.svg"
import { ReactComponent as QuestionsIcon } from "../../assets/images/icons/ic-questions.svg"
import { ReactComponent as RepeatIcon } from "../../assets/images/icons/ic-repeat.svg"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-test-active.svg"
import { ReactComponent as TrophyIcon } from "../../assets/images/icons/ic-trophy.svg"
import { ReactComponent as TestVariantIcon } from "../../assets/images/icons/ic-test-variant.svg"
import { Each } from "../../common/Each"
import { DialogStatus, TestStatus, TestType } from "../../enums"
import { formatDateV2, formatTimeV2 } from "../../utils"
import Button from "../Button"
import AlertDialog from "../dialogs/AlertDialog"
import MeatBallsMenu from "../MeatBallsMenu"
import Skeleton from "../Skeleton"
import Tag from "../Tag"
import styles from "./TestCard.module.css"

const TestCard = ({
  test = null,
  loading = false,
  onEdit = () => { },
  onDelete = () => { },
  onComplete = () => { },
  selectable = false,
  onSelect = () => { },
  changeable = false,
  onChange = () => { },
  useVariant = false,
  hideMenu = false,
  style = {}
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  // delete
  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)

  // complete
  const [openCompleteAlert, setOpenCompleteAlert] = useState(false)
  const [completeAlertStatus, setCompleteAlertStatus] = useState(DialogStatus.Default)
  const [completeCloseTimeout, setCompleteCloseTimeout] = useState(null)

  const numberOfTests = useMemo(() => {
    if (!test) {
      return;
    }
    let numberOfTests = 0
    const { innerTests } = test.content
    for (const innerTest of innerTests) {
      const { testType } = innerTest
      switch (testType) {
        case TestType.SingleChoice:
        case TestType.MultipleChoice:
          numberOfTests += 1
          break
        case TestType.TrueFalse:
          numberOfTests += innerTest.answers?.length > 0 ? innerTest.answers.length : 0
          break
        case TestType.TextCompletion:
          if (innerTest.words) {
            numberOfTests += innerTest.words.reduce((a, c) => {
              if (c.hidden) {
                a += 1
              }
              return a
            }, 0)
          }
          break
        default: break;
      }
    }
    return numberOfTests
  }, [test])

  const status = useMemo(() => {
    if (!test) {
      return null;
    }

    if (test.deleted_at) {
      return {
        label: t("tests.status.deleted"),
        color: "#fa6675"
      }
    }
    const { status } = test
    switch (status) {
      case TestStatus.Draft:
        return {
          label: t("tests.status.draft"),
          color: "rgba(var(--text-color-rgb), 24%)"
        }
      case TestStatus.Completed:
        return {
          label: t("tests.status.completed"),
          color: "#2fc6a0"
        }
      default: return null
    }
  }, [test])

  const menuActions = useMemo(() => {
    if (!test) return []

    const actions = [
      {
        label: t("tests.delete"), icon: DeleteIcon, style: { color: "var(--secondary)" }, onClick: () => setOpenAlert(true)
      }
    ]
    if (test.status === TestStatus.Draft) {
      actions.unshift({
        label: t("tests.edit"), icon: EditIcon, style: { color: "var(--primary)" }, onClick: onEdit
      },)
    }

    if (test.status === TestStatus.Public) {
      actions.unshift({
        label: t("tests.end"), icon: CheckIcon, style: { color: "var(--primary)" }, onClick: () => setOpenCompleteAlert(true)
      })
    }

    if (test.status === TestStatus.Public || test.status === TestStatus.Completed) {
      actions.unshift({
        label: t("tests.open"), icon: ExerciseIcon, style: { color: "var(--tertiary)" }, onClick: () => navigate(`/tests/${test.id}`),
      })
    }


    return actions
  }, [test])

  const tags = useMemo(() => {
    if (!test) {
      return;
    }
    const { content } = test
    const { innerTests } = content
    const uniqueTags = {}
    for (const innerTest of innerTests) {
      const { tags } = innerTest
      for (const tag of tags)
        if (!uniqueTags[tag.id]) {
          uniqueTags[tag.id] = tag
        }
    }

    return Object.values(uniqueTags)
  }, [test])

  const deleteTest = useCallback(async () => {
    try {
      setAlertStatus(DialogStatus.Loading)
      await api.delete(`/teacher/tests/${test.id}`)
      onDelete(test.id)
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [test, onDelete])

  const completeTest = useCallback(async () => {
    try {
      setCompleteAlertStatus(DialogStatus.Loading)
      await api.put(`/teacher/tests/${test.id}`, { status: TestStatus.Completed })
      onComplete(test.id)
      setCompleteAlertStatus(DialogStatus.Success)
      setCompleteCloseTimeout(setTimeout(() => {
        setOpenCompleteAlert(false)
        setCompleteAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setCompleteAlertStatus(DialogStatus.Error)

      setCompleteCloseTimeout(setTimeout(() => {
        setOpenCompleteAlert(false)
        setCompleteAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [test, onComplete])

  return (
    <div className={test?.deleted_at && !loading ? `${styles.container} ${styles.deleted}` : styles.container}
      style={style}
      onClick={() => {
        if (!loading && !hideMenu)
          navigate(`/tests/${test?.id}`)
      }}
    >
      {
        loading === true &&
        <>
          <div className={styles.header}>
            <Skeleton type="rect" width="144px" height="16px" borderRadius="8px" />
            {
              !selectable && !hideMenu &&
              <MeatBallsMenu disabled />
            }
          </div>
          <div className={styles.content}>
            <div style={{ display: "flex", flexDirection: "column", gap: ".2rem" }}>
              <Skeleton type="rect" width="196px" height="16px" borderRadius="8px" />
              <Skeleton type="rect" width="96px" height="16px" borderRadius="8px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="72px" height="14px" borderRadius="8px" />
              <Skeleton type="rect" width="256px" height="16px" borderRadius="8px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="64px" height="14px" borderRadius="8px" />
              <Skeleton type="rect" width="180px" height="16px" borderRadius="8px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="72px" height="14px" borderRadius="8px" />
              <div className={styles.tags}>
                <Each
                  of={[130, 140, 86, 44, 112]}
                  render={(tagWidth) => (
                    <Skeleton type="rect" width={`${tagWidth}px`} height="24px" borderRadius="8px" />
                  )}
                />
              </div>
            </div>
            {
              selectable &&
              <Skeleton type="rect" width="100%" height="40px" borderRadius="40px" />
            }
          </div>
        </>
      }

      {
        !loading &&
        <>
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <div className={styles.name}>
                {(useVariant || changeable) && <TestVariantIcon style={{ marginRight: "1rem" }} />}
                {!useVariant && <BookIcon style={{ color: "var(--primary)", marginRight: ".2rem" }} />}
                {test.name ?? ""}
              </div>
              {status && !selectable && !changeable &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": status.color }}>{status.label.toUpperCase()}</div>
                </div>
              }
              {test.publishable && test.status === TestStatus.Draft && !selectable && !changeable &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": "var(--tertiary)" }}>{t("tests.publishable").toUpperCase()}</div>
                </div>
              }
              {!test.publishable && test.status === TestStatus.Draft && !selectable && !changeable &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": "var(--secondary)" }}>{t("tests.toBeCompleted").toUpperCase()}</div>
                </div>
              }
            </div>
            {!test.deleted_at && !selectable && !changeable && !hideMenu && <MeatBallsMenu actions={menuActions} />}
            {
              changeable &&
              <Button
                accentColor={"var(--sf-orange)"}
                style={{ padding: '.5rem 1rem' }}
                onClick={onChange}
              >
                <PencilIcon />
                {t("tests.changeTest").toUpperCase()}
              </Button>
            }
          </div>
          <div className={styles.content}>
            <div className={styles.description}>{test.description ?? ""}</div>
            <div className={styles.labeled}>
              <div className={styles.label}>{t("tests.lesson.label")}</div>
              <div className={styles.lesson}><LessonIcon /> {test.lesson?.name ?? t("tests.lesson.empty")}</div>
            </div>
            {
              test.expires_at &&
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.expiresAt")}</div>
                <div className={styles.questions}><CalendarIcon /> {formatDateV2(test.expires_at)} {formatTimeV2(test.expires_at)} </div>
              </div>
            }
            <div className={styles.row}>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.questions")}</div>
                <div className={styles.questions}><QuestionsIcon /> {numberOfTests} {(numberOfTests === 0 || numberOfTests > 1) ? t("tests.questions") : t("tests.question")}</div>
              </div>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.successThreshold.cardLabel")}</div>
                <div className={styles.questions}><TrophyIcon /> {test.success_threshold ?? 0} / {numberOfTests}</div>
              </div>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("tests.repeat")}</div>
                <div className={styles.questions}><RepeatIcon /> {test.canBeRetried ? t("yes") : t("no")}</div>
              </div>
            </div>
            <div className={styles.labeled}>
              <div className={styles.label}>{t("tests.tags")}</div>
              <div className={styles.tags}>
                {
                  tags && tags.length > 0 &&
                  <Each
                    of={tags}
                    render={(tag) => (
                      <Tag tag={tag} />
                    )}
                  />
                }
                {
                  (!tags || tags.length === 0) && <div>{t("tests.noTags")}</div>
                }
              </div>
            </div>
            {
              selectable &&
              <div className={styles.button}>
                <Button
                  fullWidth
                  accentColor={"var(--tertiary)"}
                  onClick={onSelect}
                >
                  {t("tests.chooseTest").toUpperCase()}
                </Button>
              </div>
            }
          </div>
        </>
      }

      <AlertDialog
        open={openAlert}
        title={t("tests.deleteTitle")}
        text={t("tests.deleteText")}
        status={alertStatus}
        onClose={() => {
          setOpenAlert(false)
          setAlertStatus(DialogStatus.Default)
          if (closeTimeout) {
            clearTimeout(closeTimeout)
          }
        }}
        actions={[
          {
            label: t("tests.deleteConfirm").toUpperCase(),
            onClick: deleteTest,
          }
        ]}
      />
      <AlertDialog
        open={openCompleteAlert}
        title={t("tests.completeTitle")}
        text={t("tests.completeText")}
        status={completeAlertStatus}
        onClose={() => {
          setOpenCompleteAlert(false)
          setCompleteAlertStatus(DialogStatus.Default)
          if (completeCloseTimeout) {
            clearTimeout(completeCloseTimeout)
          }
        }}
        actions={[
          {
            label: t("tests.completeConfirm").toUpperCase(),
            onClick: completeTest,
          }
        ]}
      />
    </div >
  )
}

export default TestCard
