import { useCallback, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import TextareaAutosize from 'react-textarea-autosize'
import { v4 } from "uuid"
import api from "../../api"
import { DialogStatus } from "../../enums"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as InsertDocumentIllustration } from "../../assets/images/illustrations/il-insert-document.svg"
import Button from "../Button"
import Card from "../cards/Card"
import { Each } from "../../common/Each"
import MaterialButton from "../MaterialButton"
import DropdownSelection from "../DropdownSelection"
import TextInput from "../TextInput"
import Dialog from "./Dialog"
import styles from "./ExerciseDialog.module.css"

const ExerciseDialog = ({ open = false, exercise = null, onClose = () => { }, onSave = () => { } }) => {
  const { t } = useTranslation()

  const [name, setName] = useState(null)
  const [description, setDescription] = useState("")
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [expiresAt, setExpiresAt] = useState(null)
  const [modules, setModules] = useState([])
  const [lessons, setLessons] = useState([])
  const [status, setDialogStatus] = useState(DialogStatus.Default)
  const [files, setFiles] = useState([])

  const dropzoneRef = useRef(null)

  useEffect(() => {
    const getModules = async () => {
      try {
        const lessons = await api.get("/teacher/lessons");
        const lessonsPerModule = {}

        // group lessons by moduleId
        for (const lesson of lessons) {
          if (lesson.module.id in lessonsPerModule) {
            lessonsPerModule[lesson.module.id].lessons.push(lesson)
          } else {
            lessonsPerModule[lesson.module.id] = {
              id: lesson.module.id,
              label: lesson.module.name,
              lessons: [lesson]
            }
          }
        }
        const modules = Object.values(lessonsPerModule)
        setModules(modules)
      } catch (e) {
        console.error(e);
      }
    };

    getModules()

  }, [])

  useEffect(() => {
    if (!open) {
      setName("")
      setDescription("")
      setSelectedModule(null)
      setSelectedLesson(null)
      setExpiresAt(null)
      setDialogStatus(DialogStatus.Default)
      setFiles([])
    }

  }, [open])

  useEffect(() => {
    if (exercise) {
      setName(exercise.name)
      setDescription(exercise.description)

      if (exercise.expires_at) {
        setExpiresAt(new Date(exercise.expires_at))
      }
      if (exercise?.module && modules.length > 0) {
        const midx = modules.findIndex(m => m.id === exercise.module.id)
        if (midx >= 0) {
          setSelectedModule(midx)
        }

        if (exercise.lesson) {
          const lidx = modules[midx].lessons.findIndex(l => l.id === exercise.lesson?.id)
          if (lidx >= 0) setSelectedLesson(lidx)
        }
      }
    }
  }, [exercise, modules])

  useEffect(() => {
    if (selectedModule >= 0 && modules[selectedModule] && modules[selectedModule].id) {
      const { lessons } = modules[selectedModule]
      const l = lessons.map((l) => {
        return {
          ...l,
          label: l.name
        }
      })
      l.unshift({ id: null, label: t("exercises.lesson.empty") })
      setLessons(l)
    } else {
      setLessons([])
    }
  }, [selectedModule])

  const onAction = useCallback(async () => {
    const id = exercise?.id ?? null
    const module_id = selectedModule !== null ? modules[selectedModule]?.id : null
    const lesson_id = selectedLesson !== null ? lessons[selectedLesson]?.id : null
    const expires_at = expiresAt ? new Date(expiresAt).toISOString() : null

    setDialogStatus(DialogStatus.Loading)

    const newExercise = {
      name,
      description,
      expires_at,
      module_id,
      lesson_id,
    }

    try {
      if (!id) {
        // Create exercise
        const form = new FormData()
        for (const k in newExercise) {
          if (newExercise[k] !== null) {
            form.append(k, newExercise[k])
          }
        }

        for (let i = 0; i < files.length; i++) {
          form.append('files', files[i]);
        }
        await api.post("/teacher/exercises", form, { headers: { "Content-Type": "multipart/form-data" } })
      } else {
        // Update
        await api.put(`/teacher/exercises/${id}`, newExercise)
      }

      setDialogStatus(DialogStatus.Success)
      onSave()
    } catch (e) {
      setDialogStatus(DialogStatus.Error)
      console.error(e)
    }

  }, [exercise, name, description, selectedModule, selectedLesson, expiresAt, modules, lessons, files, onSave])

  return (
    <Dialog
      title={!exercise ? t("exercises.addTitle") : t("exercises.editTitle")}
      open={open}
      onClose={onClose}
      action={<Button disabled={
        status !== DialogStatus.Default || !name || selectedModule === null
      } onClick={onAction}>{t("exercises.save").toUpperCase()} </Button>}
      status={status}
    >
      <div className={styles.dialog}>
        <TextInput
          type="text"
          placeholder={t("exercises.name")}
          onKeyUp={(s) => {
            setName(s)
          }}
          value={name}
        />

        <Card hover style={{ padding: ".5rem 1rem", backgroundColor: "transparent" }}>
          <div className={styles.textAreaContainer}>
            <TextareaAutosize
              placeholder={t("exercises.description")}
              value={description}
              minRows={3} maxRows={10}
              type="text"
              className={styles.textArea}
              onChange={(e) => {
                const { value } = e.target
                setDescription(value)
              }} />
          </div>
        </Card>

        <DropdownSelection
          disabled={modules.length === 0}
          placeholder={t("exercises.module.placeholder")}
          appereance="transparent"
          defaultOptionIndex={selectedModule}
          options={modules}
          onSelect={(index) => {
            if (index !== selectedModule) {
              setSelectedModule(index)
            }
          }} />

        <DropdownSelection
          disabled={lessons.length === 0}
          placeholder={t("exercises.lesson.placeholder")}
          appereance="transparent"
          defaultOptionIndex={selectedLesson}
          options={lessons}
          onSelect={(index) => {
            if (index !== selectedLesson) {
              setSelectedLesson(index)
            }
          }} />


        <TextInput
          type="date"
          value={expiresAt}
          defaultValue={exercise?.expires_at ? new Date(exercise.expires_at) : null}
          placeholder={t("exercises.expiresAt")}
          onKeyUp={(d) => setExpiresAt(d)}
        />

        {
          !exercise &&
          <div className={styles.dropzoneContainer}>
            <div className={styles.subtitle} >
              {t("exercises.materials")}
              {files.length > 0 &&
                <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                  if (dropzoneRef && dropzoneRef.current) {
                    dropzoneRef.current.click();
                  }
                }} />
              }
            </div>
            {
              files.length > 0 && status === DialogStatus.Default &&
              <div className={styles.filesContainer}>
                <div className={styles.files}>
                  <Each
                    of={files}
                    render={(file) => (
                      <div className={styles.file}>
                        <MaterialButton
                          material={file}
                          onClick={() => {
                            setFiles((prevFiles) => {
                              const idx = prevFiles.findIndex(f => f.id === file.id)
                              if (idx > -1) prevFiles.splice(idx, 1)
                              return [...prevFiles]
                            })
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            }
            {
              files.length === 0 && status === DialogStatus.Default &&
              <Dropzone
                onDrop={(newFiles) => {
                  newFiles.forEach(f => f.id = v4())
                  setFiles([...files, ...newFiles])
                }}>
                {({ getRootProps, getInputProps }) => (
                  <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                    <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                      <input {...getInputProps()} />
                      <div className={styles.dropzone}>
                        <InsertDocumentIllustration />
                        <div className={styles.dropzoneLabel}>
                          {t("materials.dragAndDrop")}
                        </div>
                        <Button
                          style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                          accentColor={"var(--tertiary)"}
                          onClick={() => dropzoneRef?.current.click()}
                        >
                          {t("materials.selectFile").toUpperCase()}
                        </Button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            }
          </div>
        }

      </div>
    </Dialog>
  )

}

export default ExerciseDialog
