
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/icons/ic-close.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import Checkbox from "../components/Checkbox"
import CircularProgess from "../components/CircularProgress"
import SimpleCollapsable from "../components/SimpleCollapsable"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import { TestResultStatus, TestType } from "../enums"
import { toLetter } from "../utils"
import StudentTestResult from "./StudentTestResult"
import styles from "./TestResult.module.css"
import TrueFalseBadge from './../components/tests/TrueFalseBadge';
import ReactMarkdown from "react-markdown"
const { Buffer } = require("buffer")


let statusTimeout = null
const TestResult = ({ }) => {

  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const { testId } = useParams()

  const [loading, setLoading] = useState(false)
  const [testResult, setTestResult] = useState(null)
  const [students, setStudents] = useState([])
  const [expanded, setExpanded] = useState({})
  const [currentStudent, setCurrentStudent] = useState(null)
  const [showStudentPage, setShowStudentPage] = useState(false)
  const [markAsCorrectStatus, setMarkAsCorrectStatus] = useState({
    id: null,
    status: null
  })

  const innerTests = useMemo(() => {
    if (!testResult) return []
    const { innerTests } = testResult.content
    const { user_tests } = testResult
    for (let i = 0; i < innerTests.length; i++) {


      const { id, testType } = innerTests[i]

      switch (testType) {
        case TestType.SingleChoice:
        case TestType.MultipleChoice:
          { // per ogni innerTest creo una lista degli studenti che hanno risposto
            const { answers } = innerTests[i]
            let numberOfAnswers = 0
            for (let j = 0; j < answers.length; j++) {
              const { id: answerId } = answers[j]
              const answerStudents = []
              user_tests.forEach(ut => {
                const t = ut.content.content.filter(i => i.id === id)[0]
                if (t) {
                  const { responses } = t
                  const userResponse = responses.find(r => r.id === answerId)
                  if (userResponse.value === true) {
                    answerStudents.push(ut.user)
                  }
                }
              });
              innerTests[i].answers[j].students = answerStudents
              numberOfAnswers += answerStudents.length
            }
            let sum = 0
            for (let j = 0; j < innerTests[i].answers.length; j++) {
              const answerStudents = innerTests[i].answers[j].students
              innerTests[i].answers[j].percentage = answerStudents.length > 0 ? Math.round(answerStudents.length / numberOfAnswers * 100) : 0
              sum += innerTests[i].answers[j].percentage
            }

            if (sum < 100) {
              for (let j = 0; j < innerTests[i].answers.length; j++) {
                if (innerTests[i].answers[j].percentage > 0) {
                  innerTests[i].answers[j].percentage += (100 - sum)
                  break
                }
              }
            }
          }
          break;
        case TestType.TextCompletion:
          {
            // per ogni parola creo una lista delle parole date dagli studenti
            const { words } = innerTests[i]
            for (let j = 0; j < words.length; j++) {
              if (words[j].hidden) {
                const usersAnswers = []
                user_tests.forEach(ut => {
                  const t = ut.content.content.filter(i => i.id === id)[0]
                  if (t) {
                    const { responses } = t
                    const userResponse = responses.find(r => r.index === j)
                    const isCorrect = words[j].solutions.map(s => s.toLowerCase()).includes(userResponse.value.toLowerCase())
                    usersAnswers.push({
                      user: ut.user,
                      answer: userResponse.value,
                      isCorrect
                    })
                  }
                });
                innerTests[i].words[j].usersAnswers = usersAnswers
                innerTests[i].words[j].id = `${innerTests[i].words[j].text}-${i}-${j}`
                const correct = usersAnswers.filter(ua => ua.isCorrect === true).length
                const wrong = usersAnswers.filter(ua => ua.isCorrect === false).length
                const l = usersAnswers.length
                innerTests[i].words[j].correct_percentage = l > 0 ? Math.round(correct / l * 100) : 0
                innerTests[i].words[j].wrong_percentage = l > 0 ? Math.round(wrong / l * 100) : 0
              }
            }
          }
          break;
        case TestType.TrueFalse:
          { // per ogni innerTest creo una lista degli studenti che hanno risposto
            const { answers } = innerTests[i]
            for (let j = 0; j < answers.length; j++) {
              const { id: answerId, value } = answers[j]
              const usersAnswers = []
              user_tests.forEach(ut => {
                const t = ut.content.content.filter(i => i.id === id)[0]
                if (t) {
                  const { responses } = t
                  const userResponse = responses.find(r => r.id === answerId)
                  usersAnswers.push({
                    user: ut.user,
                    value: userResponse.value,
                    isCorrect: userResponse.value === value
                  })
                }
              });
              innerTests[i].answers[j].usersAnswers = usersAnswers
            }
            for (let j = 0; j < innerTests[i].answers.length; j++) {
              const trues = innerTests[i].answers[j].usersAnswers.filter(s => s.value).length
              const falses = innerTests[i].answers[j].usersAnswers.filter(s => s.value === false).length
              innerTests[i].answers[j].true_percentage = trues > 0 ? Math.round(trues / (trues + falses) * 100) : 0
              innerTests[i].answers[j].false_percentage = falses > 0 ? Math.round(falses / (trues + falses) * 100) : 0
            }
          }
        default: break
      }


    }

    return innerTests

  }, [testResult, students])

  const circularData = useMemo(() => {
    if (students.length === 0 || innerTests.length === 0) {
      return []
    }
    let passed = 0
    let failed = 0
    let notCompleted = 0
    const successThreshold = testResult.success_threshold ?? 0
    for (const [i, student] of students.entries()) {
      let correct = 0
      let wrong = 0
      for (const innerTest of innerTests) {
        const { testType } = innerTest
        if (testType === TestType.SingleChoice || testType === TestType.MultipleChoice) {
          const { answers } = innerTest
          for (const answer of answers) {
            const isStudentAnswer = answer.students.map((s) => s.id).includes(student.id)
            if (isStudentAnswer) {
              if (answer.isCorrect) {
                correct += 1
              } else if (!answer.isCorrect) {
                wrong += 1
              }
            }
          }
        } else if (testType === TestType.TextCompletion) {
          const { words } = innerTest
          const unknowns = words.filter(w => w.hidden)
          for (const word of unknowns) {
            const { usersAnswers } = word
            const userAnswer = usersAnswers.find(ua => ua.user.id === student.id)
            if (userAnswer && userAnswer.isCorrect) {
              correct += 1
            } else if (userAnswer && userAnswer.isCorrect === false) {
              wrong += 1
            }
          }
        } else if (testType === TestType.TrueFalse) {
          const { answers } = innerTest
          for (const answer of answers) {
            const { usersAnswers } = answer
            const userAnswer = usersAnswers.find(ua => ua.user.id === student.id)
            if (userAnswer && userAnswer.isCorrect) {
              correct += 1
            } else if (userAnswer && userAnswer.isCorrect === false) {
              wrong += 1
            }
          }
        }
      }
      students[i].clickable = false

      if ((correct + wrong) === 0) {
        notCompleted += 1
        students[i].resultStatus = TestResultStatus.NotCompleted
        students[i].statusClass = `${styles.studentStatus} ${styles.notCompleted}`
        students[i].statusLabel = t("tests.status.incomplete")
      } else if (correct > 0 && correct >= successThreshold) {
        passed += 1
        students[i].resultStatus = TestResultStatus.Passed
        students[i].statusClass = `${styles.studentStatus} ${styles.passed}`
        students[i].statusLabel = t("tests.status.passed")
        students[i].clickable = true
      } else {
        failed += 1
        students[i].resultStatus = TestResultStatus.Failed
        students[i].statusClass = `${styles.studentStatus} ${styles.failed}`
        students[i].statusLabel = t("tests.status.failed")
        students[i].clickable = true
      }
    }


    const order = {
      [TestResultStatus.Passed]: 0,
      [TestResultStatus.Failed]: 1,
      [TestResultStatus.NotCompleted]: 2,
    }

    students.sort((a, b) => order[a.resultStatus] - order[b.resultStatus])

    const sum = passed + failed + notCompleted

    const pp = Math.round(((passed) / sum * 100) * 100) / 100
    const pf = Math.round(((failed) / sum * 100) * 100) / 100
    const pnd = Math.round(((notCompleted) / sum * 100) * 100) / 100

    return [
      {
        id: "passed",
        value: pp,
        rawValue: passed,
        color: 'var(--sf-light-green)',
        label: t("tests.passed")
      },
      {
        id: "failed",
        value: pf,
        rawValue: failed,
        color: 'var(--sf-red)',
        label: t("tests.failed")
      },
      {
        id: "notCompleted",
        value: pnd,
        rawValue: notCompleted,
        color: '#B5B8BE',
        label: t("tests.notCompleted")
      },
    ]
  }, [students, innerTests, testResult])



  useEffect(() => { window.scrollTo(0, 0) }, [showStudentPage])

  useEffect(() => {

    getTestResult()

    return () => {
      if (statusTimeout) {
        clearTimeout(statusTimeout)
        statusTimeout = null
      }
    }
  }, []);


  const getTestResult = useCallback(async () => {

    const getStudents = async (moduleId, testResult) => {
      try {
        const result = await api.get(`/teacher/modules/${moduleId}/students`);

        const students = result.map((s) => {
          return {
            ...s,
            resultStatus: TestResultStatus.NotCompleted,
            statusClass: `${styles.studentStatus} ${styles.notCompleted}`,
            statusLabel: t("tests.status.incomplete")
          }
        })

        setStudents(students)
      } catch (e) {
        console.error(e)
      }
    }

    try {
      const tr = await api.get(`/teacher/tests/${testId}`);
      setTestResult(tr)
      const { module } = tr
      getStudents(module.id, tr)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const onStudentClick = useCallback((studentId) => {
    const student = students.find(s => s.id === studentId)
    setCurrentStudent(student)
    setShowStudentPage(true)
  }, [students])

  const onBack = useCallback(() => {
    setShowStudentPage(false)
    setCurrentStudent(null)
  }, [])

  const onContact = useCallback(() => {
    const base64 = Buffer.from(`${currentStudent.id}_${currentStudent.name}_${currentStudent.surname}`, "utf-8").toString("base64")
    navigate(`/messages?chat=${base64}`)
  }, [currentStudent])

  const markAsCorrect = useCallback(async (innerTest, userAnswer, wordId) => {
    const { user, answer } = userAnswer
    try {
      setMarkAsCorrectStatus({
        id: `${user.id}-${answer}-${wordId}`,
        status: "loading"
      })
      const i = innerTests.findIndex(it => it.id === innerTest.id)
      if (i >= 0) {

        const j = innerTests[i].words.findIndex(w => w.id === wordId)
        if (j >= 0) {
          innerTests[i].words[j].solutions.push(answer.toLowerCase())
        }
      }

      const updatedInnerTests = JSON.parse(JSON.stringify(innerTests)) // trick to deep copy and object with all its nested object
      for (let i = 0; i < updatedInnerTests.length; i++) {
        const { testType } = updatedInnerTests[i]
        if (testType === TestType.TextCompletion) {
          for (let j = 0; j < updatedInnerTests[i].words.length; j++) {
            if (updatedInnerTests[i].words[j].hidden) {
              // remove temporary data from word
              const { text, hidden, solutions, ...tempData } = updatedInnerTests[i].words[j]
              updatedInnerTests[i].words[j] = { text, hidden, solutions }
            }
          }
        } else if (testType === TestType.SingleChoice || testType === TestType.MultipleChoice) {
          for (let j = 0; j < updatedInnerTests[i].answers.length; j++) {
            // remove temporary data from answer
            const { id, text, isCorrect, ...tempData } = updatedInnerTests[i].answers[j]
            updatedInnerTests[i].answers[j] = { id, text, isCorrect }
          }
        }
      }

      const update = {
        content: { innerTests: updatedInnerTests }
      }

      await api.put(`/teacher/tests/${testId}`, update)
      setMarkAsCorrectStatus({
        id: `${user.id}-${answer}-${wordId}`,
        status: "success"
      })
      getTestResult()
    } catch (e) {
      console.error(e)
      setMarkAsCorrectStatus({
        id: `${user.id}-${answer}-${wordId}`,
        status: "error"
      })
    }

    statusTimeout = setTimeout(() => {
      setMarkAsCorrectStatus({
        id: null,
        status: null
      })
      statusTimeout = null
    }, 3000)

  }, [testId, innerTests])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Test</title>
        </Helmet>
      </HelmetProvider>
      {
        showStudentPage === false &&
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.sectionInner}>
              <div className={styles.header}>
                <div className={styles.title}>{testResult?.name}</div>
              </div>
              <div className={styles.top}>
                <Card style={{ flexGrow: 1 }}>
                  <div className={styles.recap}>
                    <div className={styles.cardTitle}>{t("tests.recapTitle")}</div>
                    <div className={styles.circularChartColumn}>
                      <CircularProgess data={circularData.filter(d => d.id !== "notCompleted")}></CircularProgess>
                      <div className={styles.chartLabels}>
                        {circularData.map(data => {
                          return (
                            <div className={styles.chartCell} key={data.label}>
                              <div className={styles.chartRow}>
                                <div className={styles.chartColumn}>
                                  <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                </div>
                                <div className={styles.chartColumn}>
                                  <div className={styles.chartDataTitle}>{data.rawValue}</div>
                                  <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </Card>

                <Card style={{
                  flexGrow: 1,
                  maxHeight: "400px",
                  overflowY: "scroll",
                }}>
                  <div className={styles.students}>
                    <div className={styles.cardTitle}>{t("tests.students")}</div>
                    <Each
                      of={students}
                      render={(student) => (
                        <div className={styles.student} onClick={() => {
                          if (student.clickable) {
                            onStudentClick(student.id)
                          }
                        }}
                          style={{ cursor: student.clickable ? "pointer" : "default" }}
                        >
                          <div>
                            <img className={styles.picture} src={student.picture ?? UserPlaceholder} alt="" />
                            <div className={styles.name}>{student.name} {student.surname}</div>
                          </div>
                          <div className={student.statusClass}>{student.statusLabel.toUpperCase()}</div>
                        </div>
                      )}
                    />
                  </div>
                </Card>
                <div className={styles.content}>
                  <Each
                    of={innerTests}
                    render={(innerTest, innerTestIndex) => (
                      <Card style={{ flexGrow: 1, padding: innerTest.testType === TestType.TrueFalse ? "2rem 0" : 0 }}>
                        <div className={styles.innerTest}>
                          {
                            innerTest.text &&
                            <div className={styles.cardTitle} style={{
                              padding: "0 1rem",
                              paddingTop: "1rem",
                            }}>{innerTest.text}
                            </div>
                          }
                          {
                            innerTest.testType === TestType.TextCompletion &&
                            <div className={styles.words}>
                              <Each
                                of={innerTest.words}
                                render={(word) => (
                                  <div className={word.hidden ? `${styles.word} ${styles.hidden}` : styles.word}>{word.text}</div>
                                )}
                              />
                            </div>
                          }
                          {
                            (innerTest.testType === TestType.SingleChoice || innerTest.testType === TestType.MultipleChoice) &&
                            <Each
                              of={innerTest.answers}
                              render={(answer, index) => (
                                <div className={styles.answerContainer}>
                                  <div className={styles.answerWrapper}>
                                    <div className={styles.answer}>
                                      <div className={styles.left}>
                                        <div className={answer.isCorrect ? styles.correctLabel : styles.wrongLabel}>{toLetter(index + 1).toUpperCase()}</div>
                                        <ReactMarkdown children={answer.text} />
                                      </div>
                                      {answer.isCorrect && <Checkbox value={true} />}
                                    </div>
                                    <div className={styles.answerStats}>
                                      <div className={answer.isCorrect ? `${styles.percentage} ${styles.correct}` : styles.percentage}>{answer.percentage}%</div>
                                      <div className={styles.barContainer}>
                                        <div className={answer.isCorrect ? `${styles.bar} ${styles.correct}` : styles.bar}
                                          style={{
                                            width: answer.percentage === 0 ? "2%" : `${answer.percentage}%`
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className={answer.percentage === 0 ? `${styles.chevron} ${styles.disabled}` :
                                      expanded === answer.id ? `${styles.chevron} ${styles.open}` : styles.chevron}
                                      onClick={() => {
                                        if (answer.percentage === 0) {
                                          return
                                        }

                                        if (expanded === answer.id) {
                                          setExpanded(null)
                                        } else {
                                          setExpanded(answer.id)
                                        }
                                      }}
                                    >
                                      <ChevronIcon className={expanded === answer.id ? styles.open : styles.closed} />
                                    </div>
                                  </div>
                                  <SimpleCollapsable
                                    expanded={expanded === answer.id}
                                    expadendHeight={`256px`}
                                    style={{
                                      backgroundColor: "var(--background-secondary-color)"
                                    }}
                                  >
                                    <div className={styles.answerStudents}>
                                      <Each
                                        of={answer.students}
                                        render={(student) => (
                                          <div className={styles.student} style={{ justifyContent: "flex-start", width: "33%" }} onClick={() => onStudentClick(student.id)}>
                                            <img className={styles.picture} src={student.picture ?? UserPlaceholder} alt="" />
                                            <div className={styles.name}>{student.name}</div>
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </SimpleCollapsable>
                                </div>
                              )}
                            />
                          }
                          {
                            innerTest.testType === TestType.TextCompletion &&
                            <Each
                              of={innerTest.words.filter(w => w.hidden === true)}
                              render={(word) => (
                                <div className={styles.answerContainer}>
                                  <div className={styles.answerWrapper}>
                                    <div className={styles.answer} style={{ fontWeight: 800, color: "var(--sf-light-green)", width: "50%", flexGrow: 0 }}>
                                      {word.text}
                                    </div>
                                    <div className={styles.wordStats}>
                                      <div className={styles.wordStat}>
                                        <div className={`${styles.percentage} ${styles.correct}`}>{word.correct_percentage}%</div>
                                        <div className={styles.barContainer}>
                                          <div className={`${styles.bar} ${styles.correct}`}
                                            style={{
                                              width: word.correct_percentage === 0 ? "2%" : `${word.correct_percentage}%`,
                                              minHeight: "16px"
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className={styles.wordStat}>
                                        <div className={styles.percentage}>{word.wrong_percentage}%</div>
                                        <div className={styles.barContainer}>
                                          <div className={styles.bar}
                                            style={{
                                              width: word.wrong_percentage === 0 ? "2%" : `${word.wrong_percentage}%`,
                                              minHeight: "16px"
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className={expanded === word.id ? `${styles.chevron} ${styles.open}` : styles.chevron}
                                      onClick={() => {
                                        if (expanded === word.id) {
                                          setExpanded(null)
                                        } else {
                                          setExpanded(word.id)
                                        }
                                      }}
                                    >
                                      <ChevronIcon className={expanded === word.id ? styles.open : styles.closed} />
                                    </div>
                                  </div>
                                  <SimpleCollapsable
                                    expanded={expanded === word.id}
                                    expadendHeight={`256px`}
                                    style={{
                                      backgroundColor: "var(--background-secondary-color)"
                                    }}
                                  >
                                    <div className={styles.answerStudents}>
                                      <Each
                                        of={word.usersAnswers}
                                        render={(userAnswer, index) => (
                                          <div className={styles.student} style={{ justifyContent: "flex-start", width: "50%" }} onClick={() => onStudentClick(userAnswer.user.id)}>
                                            <img className={styles.picture} src={userAnswer.user.picture ?? UserPlaceholder} alt="" />
                                            <div className={styles.studentWordContainer}>
                                              <div className={styles.name} style={{ fontSize: ".875rem" }}>{userAnswer.user.name}</div>
                                              <div className={userAnswer.isCorrect ? styles.studentWord : `${styles.studentWord} ${styles.wrong}`}>{userAnswer.answer || "Nessuna risposta"}</div>
                                            </div>
                                            {
                                              !userAnswer.isCorrect && userAnswer.answer &&
                                              <Button
                                                inverse
                                                accentColor={"var(--tertiary)"}
                                                style={{ padding: ".5rem .8rem", borderRadius: "10px" }}
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  markAsCorrect(innerTest, userAnswer, word.id)
                                                }}
                                              >
                                                {t("tests.testType.textCompletion.markAsCorrect").toUpperCase()}
                                                {markAsCorrectStatus.id === `${userAnswer.user.id}-${userAnswer.answer}-${word.id}` &&
                                                  <>
                                                    {
                                                      markAsCorrectStatus.status === "loading" &&
                                                      <span className="loader" style={{ "--accent-color": "var(--tertiary)" }} />
                                                    }
                                                    {
                                                      markAsCorrectStatus.status === "success" &&
                                                      <CheckIcon className={styles.successIcon} style={{
                                                        color: "var(--sf-light-green)"
                                                      }} />
                                                    }
                                                    {
                                                      markAsCorrectStatus.status === "error" &&
                                                      <ErrorIcon className={styles.errorIcon} style={{ color: "var(--sf-red)" }} />
                                                    }
                                                  </>
                                                }
                                              </Button>
                                            }
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </SimpleCollapsable>
                                </div>
                              )}
                            />
                          }

                          {
                            innerTest.testType === TestType.TrueFalse &&
                            <Each
                              of={innerTest.answers}
                              render={(answer, index) => (
                                <div className={styles.answerContainer}>
                                  <div className={styles.answerWrapper}>
                                    <div className={styles.answer} style={{ fontWeight: 600, padding: "4px 1rem", width: "50%", flexGrow: 0 }}>
                                      <ReactMarkdown children={answer.text} />
                                      <div className={styles.badges}>
                                        <TrueFalseBadge type={true} onChange={null} value={answer.value} style={{ padding: "2px .5rem", fontSize: ".875rem" }} />
                                        <TrueFalseBadge type={false} onChange={null} value={answer.value} style={{ padding: "2px .5rem", fontSize: ".875rem" }} />
                                      </div>
                                    </div>
                                    <div className={styles.wordStats}>
                                      <div className={styles.wordStat}>
                                        <div className={`${styles.percentage} ${answer.true_percentage > 0 ? styles.correct : ""}`}>{answer.true_percentage}%</div>
                                        <div className={styles.barContainer}>
                                          <div className={`${styles.bar} ${answer.true_percentage > 0 ? styles.correct : ""}`}
                                            style={{
                                              width: answer.true_percentage === 0 ? "2%" : `${answer.true_percentage}%`,
                                              minHeight: "16px"
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className={styles.wordStat}>
                                        <div className={`${styles.percentage} ${answer.false_percentage > 0 ? styles.wrong : ""}`}>{answer.false_percentage}%</div>
                                        <div className={styles.barContainer}>
                                          <div className={`${styles.bar} ${answer.false_percentage > 0 ? styles.wrong : ""}`}
                                            style={{
                                              width: answer.false_percentage === 0 ? "2%" : `${answer.false_percentage}%`,
                                              minHeight: "16px"
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className={expanded === answer.id ? `${styles.chevron} ${styles.open}` : styles.chevron}
                                      onClick={() => {
                                        if (expanded === answer.id) {
                                          setExpanded(null)
                                        } else {
                                          setExpanded(answer.id)
                                        }
                                      }}
                                    >
                                      <ChevronIcon className={expanded === answer.id ? styles.open : styles.closed} />
                                    </div>
                                  </div>
                                  <SimpleCollapsable
                                    expanded={expanded === answer.id}
                                    expadendHeight={`256px`}
                                    style={{
                                      backgroundColor: "var(--background-secondary-color)"
                                    }}
                                  >
                                    <div className={styles.answerStudents}>
                                      <Each
                                        of={answer.usersAnswers}
                                        render={(userAnswer) => (
                                          <div className={styles.student} style={{ width: "33%" }} onClick={() => onStudentClick(userAnswer.user.id)}>
                                            <div>
                                              <img className={styles.picture} src={userAnswer.user.picture ?? UserPlaceholder} alt="" />
                                              <div className={styles.name}>{userAnswer.user.name}</div>
                                            </div>
                                            {
                                              userAnswer.value !== null &&
                                              <TrueFalseBadge type={userAnswer.value} value={answer.value} onChange={null} />
                                            }
                                            {
                                              userAnswer.value === null &&
                                              <div className={`${styles.studentWord} ${styles.wrong}`}>Nessuna risposta</div>
                                            }
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </SimpleCollapsable>
                                </div>
                              )}

                            />
                          }
                        </div>
                      </Card>

                    )}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      }

      {
        showStudentPage &&
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.sectionInner}>
              <StudentTestResult
                student={currentStudent}
                test={innerTests}
                onBack={onBack}
                onContact={onContact}
              />
            </div>
          </div>
        </div>
      }
    </HeaderFooterLayout >
  )
}

export default TestResult
