import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import TextInput from "../components/TextInput";
import { ModulePages } from "../enums";
import styles from "./ModuleLessons.module.css";
import ModuleLessonCard from "../components/cards/ModuleLessonCard";

const ModuleLessons = ({ onChangeTabValue = () => { }, prefetchedLessons = null, onEdit = () => { } }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { moduleId } = useParams()

  const [lessons, setLessons] = useState(prefetchedLessons);
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [lessonsLoading, setLessonsLoading] = useState([]);

  useEffect(() => {
    const getLessons = async () => {
      try {
        setLessonsLoading(true);
        const lessons = await api.get(`/teacher/modules/${moduleId}/lessons`);
        setLessons(lessons.sort((a, b) => (new Date(b.starts_at) - new Date(a.starts_at))));
      } catch (e) {
        console.error(e);
      } finally {
        setLessonsLoading(false);
      }
    };

    if (prefetchedLessons === null) {
      getLessons();
    }

    return () => {
      onChangeTabValue(ModulePages.Lessons, null)
    }
  }, []);

  useEffect(() => {
    if (lessons && lessons.length > 0) {
      onChangeTabValue(ModulePages.Lessons, lessons.length);
      setFilteredLessons(lessons);
    }
  }, [lessons]);

  const onSearch = useCallback(
    (text) => {
      if (lessons && lessons.length > 0) {
        if (text) {
          const filter = lessons.filter((s) => {
            const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
            const query = text ? text.toLowerCase().replaceAll(" ", "") : text
            return target.includes(query);
          });
          setFilteredLessons(filter);
        } else {
          setFilteredLessons(lessons);
        }
      }
    },
    [lessons]
  );

  return (
    <div className={styles.container}>
      <TextInput
        onKeyUp={onSearch}
        type="search"
        placeholder={"Cerca..."}
        style={{
          backgroundColor: "var(--background-secondary-color)",
        }}
      />
      <div className={styles.lessons}>
        {/* 
        // TODO creare skeleton/loading  
      */}
        <Each
          of={filteredLessons}
          render={(lesson) => (
            <ModuleLessonCard lesson={lesson} onEdit={onEdit} />
          )}
        />
      </div>
    </div>
  );
};

export default ModuleLessons;
