import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../api"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import { ButtonStatus } from "../common/constants"
import Succeeded from "../components/animated/Succeeded"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import Dialog from "../components/dialogs/Dialog"
import DropdownMultiSelection from "../components/DropdownMultiSelection"
import Tag from "../components/Tag"
import TextInput from "../components/TextInput"
import { DialogStatus } from "../enums"
import typo from "../typography.module.css"
import { checkImage, formatDateV2, formatTimeV2 } from "../utils"
import styles from "./LessonEdit.module.css"
import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    DiffSourceToggleWrapper,
    headingsPlugin,
    InsertTable,
    linkDialogPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    quotePlugin,
    Separator,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import useWindowDimensions from '../common/hooks/useWindowDimensions';

const LessonEdit = ({ lesson, tagsList = [], onSave = () => { } }) => {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const [disableSave, setDisableSave] = useState(true)

    const [openDialog, setOpenDialog] = useState(false)
    const [dialogStatus, setDialogStatus] = useState(DialogStatus.Default)
    const [alreadySuggested, setAlredySuggested] = useState(false)
    const [motivation, setMotivation] = useState(null)
    const [suggestedDate, setSuggestedDate] = useState(new Date(lesson.starts_at))

    const [name, setName] = useState(lesson.name)
    const [description, setDescription] = useState(lesson.description)
    const [date, setDate] = useState(new Date(lesson.starts_at))
    const [_tagsList, setTagList] = useState([])
    const [tags, setTags] = useState(lesson.tags ?? [])
    const [url, setUrl] = useState("")
    const [invalidUrl, setInvalidUrl] = useState(false)
    const [file, setFile] = useState(null)
    const [saveStatus, setSaveStatus] = useState(null)

    const fileRef = useRef(null)

    useEffect(() => {
        const getSuggestion = async () => {
            try {
                const res = await api.get(`/teacher/lessons/${lesson.id}/suggestion`)
                if (res) {
                    const { suggested_date, message } = res.info
                    setAlredySuggested(true)
                    setSuggestedDate(new Date(suggested_date))
                    setMotivation(message)
                }

            } catch (e) {
                console.error(e)
            }
        }

        getSuggestion()
    }, [])

    useEffect(() => {
        setTagList(tagsList.filter((t) => {
            return !(lesson.tags && lesson.tags.map(lt => lt.id).includes(t.id))
        }))
    }, [tagsList])

    useEffect(() => {
        setDisableSave(
            name === lesson.name &&
            description === lesson.description &&
            JSON.stringify(tags) === JSON.stringify(lesson.tags ?? []) &&
            !(file || (url && !invalidUrl))
        )
    }, [name, description, tags, lesson, file, url, invalidUrl])

    useEffect(() => {
        checkImage(url)
            .then(r => {
                setInvalidUrl(r === false)
            })
            .catch(e => setInvalidUrl(true))
    }, [url])

    useEffect(() => {
        if (fileRef?.current) {
            fileRef.current.value = null
        }
    }, [file])

    const onClickHint = useCallback(() => {
        setOpenDialog(true)
    }, [setOpenDialog])

    const save = useCallback(async () => {
        setSaveStatus(ButtonStatus.Loading)
        try {
            const form = new FormData()
            form.append("name", name)
            form.append("description", description)
            if (file) {
                form.append("file", file)
            } else if (url) {
                form.append("url", url)
            }

            if (tags.length > 0) {
                form.append("tags", JSON.stringify(tags.map(t => t.id)))
            }
            const res = await api.put(`/teacher/lessons/${lesson.id}`, form, { headers: { "Content-Type": "multipart/form-data" } })
            onSave(res)
            setSaveStatus(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setSaveStatus(ButtonStatus.Error)
        }
    }, [name, description, file, url, tags, onSave, lesson])

    const sendSuggestedDate = useCallback(async () => {
        try {
            setDialogStatus(DialogStatus.Loading)

            const res = await api.post(`/teacher/lessons/${lesson.id}/suggestion`, {
                message: motivation,
                suggested_date: suggestedDate.toISOString()
            })

            setDialogStatus(DialogStatus.Success)
            setAlredySuggested(true)
        } catch (e) {
            console.error(e)
            setDialogStatus(DialogStatus.Error)
        }
    }, [motivation, suggestedDate, lesson])

    const onDialogClose = () => {
        setOpenDialog(false)
        setDialogStatus(DialogStatus.Default)
    }

    return (
        <div className={styles.container}>
            <Card>
                <div className={`${typo.title} ${styles.content}`}>
                    <div>{t("lessons.editTitle")}</div>
                    <Card style={{ padding: ".5rem 1rem" }}>
                        <div className={styles.cardContent}>
                            <div className={styles.sectionTitle}>Nome</div>
                            <TextInput
                                type={"text"}
                                value={lesson.name ?? "-"}
                                style={{
                                    padding: "1px", border: "none", backgroundColor: "transparent",
                                    fontSize: "1rem", fontWeight: 700, width: "100%"
                                }}
                                onKeyUp={v => setName(v)}
                            />
                        </div>
                    </Card>
                    <Card style={{ padding: ".5rem 0rem", fontSize: '1rem' }}>
                        <div className={styles.cardContent}>
                            <div className={styles.sectionTitle} style={{ paddingLeft: '1rem' }}>Descrizione</div>
                            <MDXEditor
                                className={styles.editor}
                                markdown={lesson.description}
                                onChange={(value) => {
                                    setDescription(value)
                                }}
                                plugins={[
                                    headingsPlugin(),
                                    listsPlugin(),
                                    quotePlugin(),
                                    thematicBreakPlugin(),
                                    linkDialogPlugin(),
                                    diffSourcePlugin(),
                                    tablePlugin(),
                                    toolbarPlugin({
                                        toolbarContents: () => (
                                            <>
                                                {' '}
                                                <UndoRedo />
                                                {width > 768 && <Separator />}
                                                <BoldItalicUnderlineToggles />
                                                {width > 768 && <Separator />}
                                                <ListsToggle />
                                                {width > 768 && <CreateLink />}
                                                {width > 768 && <InsertTable />}
                                                {width > 768 && <Separator />}
                                                {width > 768 && <BlockTypeSelect />}
                                                <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                                            </>
                                        )
                                    })
                                ]}
                            />
                        </div>
                    </Card>
                    <Card hover style={{ padding: "0.5rem 1rem", backgroundColor: "var(--background-color)" }} title={t("lessons.tags")}>
                        <div className={styles.tags}>
                            <DropdownMultiSelection
                                placeholder={t("tests.tagsPlaceholder")}
                                options={_tagsList}
                                appereance="embedded"
                                selectedOptions={tags.map((t) => {
                                    return {
                                        ...t,
                                        props: { showIcon: true }
                                    }
                                })}
                                onSelect={(newTags) => setTags(newTags)}
                                itemsRender={(item, index) => (
                                    <Tag showIcon={false} tag={item} {...item.props ?? {}} IconComponent={CloseIcon} onClose={(e) => {
                                        e.stopPropagation()
                                        tags.splice(index, 1)
                                        setTags([...tags])
                                    }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            const idx = tags.findIndex(t => t.id === item.id)
                                            if (idx >= 0) {
                                                setTags(t => {
                                                    t.splice(idx, 1)
                                                    return [...t]
                                                })
                                                setTagList(t => [...t, item])
                                            } else {
                                                setTags(t => [...t, item])
                                                setTagList(t => {
                                                    const cIdx = t.findIndex(t => t.id === item.id)
                                                    if (cIdx >= 0) {
                                                        t.splice(cIdx, 1)
                                                    }
                                                    return [...t]
                                                })
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </Card>
                    <Card
                        style={{
                            padding: ".5rem 1rem",
                            backgroundColor: "var(--background-secondary-color)",
                        }}>
                        <div className={styles.dateContainer}>
                            <div className={`${styles.cardContent}`}>
                                <div className={styles.sectionTitle}>Data</div>
                                <div className={styles.date}>
                                    <CalendarIcon />
                                    {formatTimeV2(date)}, {formatDateV2(date)}
                                </div>
                            </div>
                            <Button appearance="text" onClick={onClickHint}>
                                {t("lessons.suggestionButton").toUpperCase()}
                            </Button>
                        </div>
                    </Card>
                    <div className={styles.thumbnailSection}>
                        <img className={styles.thumbnail} src={file ? URL.createObjectURL(file) : (!invalidUrl && url ? url : lesson.thumbnail)} alt="" />
                        <div className={styles.thumbnailSettings}>
                            <div>
                                <div className={styles.size}>{t("lessons.size")}</div>
                                <div className={styles.scope}>{t("lessons.scope")}</div>
                            </div>
                            <input hidden type="file" multiple={false} accept="image/jpeg, image/png, image/webp" ref={fileRef} onChange={(e) => {
                                const { files } = e.target
                                if (files && files.length > 0) {
                                    setFile(e.target.files[0])
                                    setUrl("")
                                } else {
                                    setFile(null)
                                }
                            }} />
                            <div className={styles.thumbnailInput}>
                                <div className={styles.helperText}>{t("lessons.helperText")}</div>
                                <Card style={{
                                    padding: ".5rem 1rem",
                                }} error={invalidUrl && url}>
                                    <div className={styles.cardContent}>
                                        <div className={styles.sectionTitle}>Link</div>
                                        <TextInput
                                            type={"text"}
                                            value={url}
                                            placeholder={"https://image-url.com"}
                                            style={{
                                                padding: "1px", border: "none", backgroundColor: "transparent",
                                                fontSize: "1rem", fontWeight: 700, width: "100%"
                                            }}
                                            onKeyUp={v => {
                                                setFile(null)
                                                setUrl(v)
                                            }}
                                        />
                                    </div>
                                </Card>
                                <Button className={styles.upload} onClick={() => fileRef?.current?.click()}>
                                    {t("lessons.uploadThumbnail").toUpperCase()}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.save}>
                        <Button
                            status={saveStatus}
                            disabled={disableSave}
                            onClick={save}>
                            {t("lessons.save").toUpperCase()}
                        </Button>
                    </div>
                </div>
            </Card>
            <Dialog
                open={openDialog}
                status={dialogStatus}
                title={t("lessons.suggestionTitle")}
                onClose={onDialogClose}
                style={{ width: "30%", minWidth: "400px" }}
                action={
                    <Button
                        disabled={alreadySuggested || !motivation || !suggestedDate || suggestedDate.toISOString() === new Date(lesson.starts_at).toISOString() || dialogStatus !== DialogStatus.Default}
                        onClick={sendSuggestedDate}
                    >
                        {t("lessons.suggestionSend").toUpperCase()}
                    </Button>
                }
            >
                <div className={styles.dialog}>
                    {!alreadySuggested &&
                        <>
                            <div className={styles.suggestionMessage}>{t("lessons.suggestionMessage")}</div>
                            <TextInput
                                type={"text"}
                                placeholder={t("lessons.suggestionMotivation")}
                                value={motivation}
                                onKeyUp={v => setMotivation(v)}
                            />
                            <TextInput
                                type={"date"}
                                placeholder={t("lessons.suggestionDate")}
                                value={suggestedDate}
                                defaultValue={lesson?.starts_at ? new Date(lesson.starts_at) : null}
                                onKeyUp={(d) => setSuggestedDate(d)}
                            />

                        </>
                    }

                    {
                        alreadySuggested &&
                        <>
                            <div className={styles.succeded}><Succeeded /></div>
                            <div className={styles.suggestionMessage}> {t("lessons.suggestionDone")}</div>
                            <Card style={{
                                padding: ".5rem 1rem",
                            }} title={t("lessons.suggestionMotivation")}>
                                {motivation}
                            </Card>
                            <Card style={{
                                padding: ".5rem 1rem",
                            }}>
                                <div className={styles.dateContainer}>
                                    <div className={`${styles.cardContent}`}>
                                        <div className={styles.sectionTitle}>Data</div>
                                        <div className={styles.date}>
                                            <CalendarIcon />
                                            {formatTimeV2(suggestedDate)}, {formatDateV2(suggestedDate)}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </>

                    }

                </div>
            </Dialog>
        </div>
    )

}

export default LessonEdit
