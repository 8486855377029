import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/icons/ic-close.svg"
import { ButtonStatus } from "../common/constants"
import styles from "./Button.module.css"

const Button = ({ children, inverse, appearance = 'gradient', onClick, fullWidth, disabled, style, additionalClass, accentColor, gradientColors = ["#16C0FB", "#16A7F8"], status, ...props }) => {

    const handleOnClick = async (e) => {
        if (onClick && typeof onClick === 'function') {
            onClick(e)
        }
    }

    let classes = []

    switch (appearance) {
        case 'default':
            classes.push(styles.actionButton)
            break
        case 'text':
            classes.push(styles.textButton)
            break
        case 'social':
            classes.push(styles.socialButton)
            break
        case 'gradient':
            classes.push(styles.gradient)
            break;
        default:
            classes.push(styles.actionButton)
    }

    if (fullWidth) {
        classes.push(styles.fullWidth)
    }

    if (additionalClass) {
        classes.push(additionalClass)
    }

    if (inverse) {
        classes.push(styles.inverse)
    }

    return (
        <button className={classes.join(' ')} style={{ ...style, '--button-accent-color': accentColor ? accentColor : 'var(--primary)', '--button-gradient-start': gradientColors[0], '--button-gradient-end': gradientColors[1] }} onClick={handleOnClick} disabled={disabled}>
            {children}
            {status === ButtonStatus.Loading && <span className="loader" style={{ "--accent-color": "#fff" }} />}
            {status === ButtonStatus.Success && <CheckIcon className={styles.successIcon} />}
            {status === ButtonStatus.Error && <ErrorIcon className={styles.errorIcon} />}
        </button>
    )
}

export default Button
