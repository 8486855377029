import Button from "./Button"
import styles from "./EmptyContent.module.css"

const EmptyContent = ({
  title = "",
  message = "",
  Illustration = null,
  style = {},
  imageSrc = null,
  imageStyle = {},
  action = null,
}) => {

  return (
    <div className={styles.container}
      style={{ ...style }}
    >
      {
        Illustration &&
        <div className={styles.illustration} style={{ ...imageStyle }}>
          <Illustration />
        </div>
      }
      {
        imageSrc &&
        <div className={styles.illustration}>
          <img src={imageSrc} alt="" style={{ ...imageStyle }} />
        </div>
      }
      {
        title &&
        <div className={styles.title}>{title}</div>
      }
      {
        message &&
        <div className={styles.message}>{message}</div>
      }
      {
        action &&
        <div className={styles.action}>
          <Button
            inverse
            style={{ padding: "0.7rem 1rem" }}
            onClick={action.onClick ? action.onClick : () => { }}
            {...action.buttonProps}
          >
            {action.label}
          </Button>
        </div>
      }
    </div>
  )
}

export default EmptyContent
