import { cloneElement, useMemo } from 'react';
import { Tooltip } from 'react-tooltip'
import { v4 } from 'uuid';

const TooltipWrapper = ({ children, text = "", className = "", style = {}, html = null }) => {

  const id = useMemo(() => v4(), [])

  return (
    <>
      {html &&
        <>
          {cloneElement(children, { "data-tooltip-id": id, "data-tooltip-html": html })}
          <Tooltip id={id}
            multiline={true}
            style={{
              whiteSpace: "normal",
              maxWidth: "512px",
              zIndex: 9999,
              borderRadius: "16px",
              color: "#000",
              backgroundColor: '#fff',
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            opacity={1}
          >
            <div className={className} style={{
              ...style,
            }}>{html}</div>
          </Tooltip>
        </>
      }
      {text &&
        <>
          {cloneElement(children, { "data-tooltip-id": id, "data-tooltip-content": text })}
          <Tooltip id={id}
            multiline={true}
            style={{
              whiteSpace: "normal",
              maxWidth: "512px",
              zIndex: 9999,
              borderRadius: "16px",
              color: "#000",
              backgroundColor: '#fff',
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            opacity={1}
          >
            <div className={className} style={{
              ...style,
            }}>{text}</div>
          </Tooltip>
        </>
      }
    </>
  );
};

export default TooltipWrapper;
