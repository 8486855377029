import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as PlayIcon } from "../../assets/images/icons/ic-play.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-video-lesson.svg"
import { FeedType } from "../../common/constants"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { calcLastUpdate, capitalize, formatDateV2, formatTimeV2, hourDiff } from "../../utils"
import LiveBadge from "../LiveBadge"
import Loader from "../Loader"
import Skeleton from "../Skeleton"
import Card from "./Card"
import ExerciseFeedCard from "./ExerciseFeedCard"
import styles from "./FeedCard.module.css"
import TestFeedCard from "./TestFeedCard"
import MaterialFeedCard from "./MaterialFeedCard"

const FeedCard = ({ activity, onChange = () => { } }) => {

    const now = new Date()
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const [files, setFiles] = useState([])
    const navigate = useNavigate();

    return (
        <>
            {
                activity.loading === true &&
                <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div className={styles.cardHeader}>
                        <div className={styles.icon}>
                            <Skeleton type="circle" width="32px" height="32px" />
                        </div>
                        <div className={styles.info} style={{ gap: ".3rem" }}>
                            <div className={styles.title}>
                                <Skeleton type="rect" width="256px" height="16px" borderRadius="8px" />
                            </div>
                            <div className={styles.subtitle}>
                                <Skeleton type="rect" width="144px" height="14px" borderRadius="8px" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.content} style={{ height: "256px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Loader />
                    </div>
                </Card>
            }
            {activity.type === FeedType.Communication && !activity.loading &&
                <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div className={styles.cardHeader}>
                        <div className={`${styles.icon} ${styles.communication}`}>
                            <img src={activity.profile.picture} alt={activity.profile.surname} />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.title}>
                                {activity.profile.name} {activity.profile.surname}
                            </div>
                            <div className={styles.subtitle}>
                                Comunicazione del docente
                            </div>
                        </div>
                        <div className={styles.date}>
                            {calcLastUpdate(activity.created_at, width > 768 ? false : true, true)}
                            <ClockIcon className={styles.clockIcon} />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={`${styles.description} ${styles.communication}`}>
                            {activity.info.message}
                        </div>
                    </div>
                </Card>
            }

            {activity.type === FeedType.Lesson && !activity.loading &&
                <Card style={{ display: 'flex', flexDirection: 'column', padding: 0, cursor: 'pointer' }} onClick={() => {
                    navigate(`/lessons/${activity.object.room_name}`)
                }}>
                    <div className={styles.cardHeader}>
                        <div className={`${styles.icon} ${styles.lesson}`}>
                            <LessonIcon />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.title}>
                                {activity.object.name}
                            </div>
                            <div className={styles.subtitle}>
                                <ClockIcon className={styles.clockIcon} /> {hourDiff(activity.object.starts_at, activity.object.ends_at)} ore
                            </div>
                        </div>
                        <div className={styles.date}>
                            {now > new Date(activity.object.starts_at) && now < new Date(activity.object.ends_at) &&
                                <LiveBadge style={{ padding: '.3rem .5rem' }} />
                            }
                            {now < new Date(activity.object.starts_at) &&
                                <>
                                    {formatTimeV2(activity.object.starts_at)} {capitalize(formatDateV2(activity.object.starts_at, { weekday: 'short', day: 'numeric', month: 'short' }))}
                                    <CalendarIcon />
                                </>
                            }
                            {now > new Date(activity.object.ends_at) &&
                                <div className={styles.lessonEnded}>TERMINATA</div>
                            }
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.thumbnailContainer}>
                            <img src={activity.object.thumbnail} className={`${styles.thumbnail} ${now > new Date(activity.object.ends_at) ? styles.ended : ''}`} alt="lesson" />
                            <div className={styles.playButton}>
                                <PlayIcon />
                            </div>
                        </div>
                        <div className={styles.description}>
                            {activity.object.description}
                        </div>
                    </div>
                </Card>
            }

            {activity.type === FeedType.Material && !activity.loading &&
                <MaterialFeedCard
                    activity={activity}
                    onChange={onChange}
                />
            }

            {activity.type === FeedType.Exercise && !activity.loading &&
                <ExerciseFeedCard
                    activity={activity}
                    onEdit={onChange}
                    onDelete={(activity_id) => onChange({ activity_id, deleted: true })}
                />
            }

            {
                activity.type === FeedType.Test && !activity.loading &&
                <TestFeedCard
                    activity={activity}
                    onDelete={(activity_id) => onChange({ activity_id, deleted: true })}
                    onComplete={onChange}
                />
            }
        </>
    )
}

export default FeedCard
