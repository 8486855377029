import { useCallback, useContext, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import TextareaAutosize from 'react-textarea-autosize'
import { v4 } from "uuid"
import api from "../../api"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as CommunicationIcon } from "../../assets/images/icons/ic-announcement.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise.svg"
import { ReactComponent as MaterialIcon } from "../../assets/images/icons/ic-files-active.svg"
import { ReactComponent as TestIcon } from "../../assets/images/icons/ic-test-add.svg"
import { ReactComponent as InsertDocumentIllustration } from "../../assets/images/illustrations/il-insert-document.svg"
import { Each } from "../../common/Each"
import MainContext from "../../common/MainContext"
import { FeedContentType, TestStatus } from "../../enums"
import Button from "../Button"
import Collapsable from "../Collapsable"
import DropdownSelection from "../DropdownSelection"
import IconButton from "../IconButton"
import MaterialButton from "../MaterialButton"
import TextInput from "../TextInput"
import Dialog from "../dialogs/Dialog"
import styles from "./FeedInputCard.module.css"
import TestCard from "./TestCard"
import { ButtonStatus } from "../../common/constants"

const FeedInputCard = ({ moduleId, prefetchedLessons = null, onChange = () => { } }) => {

  const { t } = useTranslation();
  const context = useContext(MainContext)
  const [selectedType, setSelectedType] = useState(FeedContentType.Communication);
  const [text, setText] = useState("")
  const [height, setHeight] = useState(null)
  const [placeholder, setPlaceholder] = useState(t("typeHere"))
  const [publishing, setPublishing] = useState(false)
  const [publishDisabled, setPublishDisabled] = useState(true)
  const [publishingStatus, setPublishingStatus] = useState(null)
  const [publishTimeout, setPublishTimeout] = useState(null)

  // materials
  const [files, setFiles] = useState([])
  const [selectedMaterialLesson, setSelectedMaterialLesson] = useState(null)

  // test 
  const [expiresAt, setExpiresAt] = useState(null)
  const [selectedTest, setSelectedTest] = useState(null)
  const [tests, setTests] = useState([])
  const [filteredTests, setFilteredTests] = useState([])
  const [loadingTests, setLoadingTests] = useState(false)
  const [openTestsDialog, setOpenTestsDialog] = useState(false)

  // exercise
  const [name, setName] = useState(null)
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [exerciseExpiresAt, setExerciseExpiresAt] = useState(null)
  const [exerciseFiles, setExerciseFiles] = useState([])
  const [lessons, setLessons] = useState([])

  const dropzoneRef = useRef(null)
  const materialRef = useRef(null)

  const testRef = useRef(null)

  const exerciseRef = useRef(null)
  const exerciseDropzoneRef = useRef(null)

  useEffect(() => {
    let disabled = true
    switch (selectedType) {
      case FeedContentType.Communication:
        disabled = !text
        break
      case FeedContentType.Exercise:
        disabled = (!name || !text)
        break
      case FeedContentType.Material:
        disabled = (files.length === 0)
        break
      case FeedContentType.Test:
        disabled = (selectedTest === null)
        break
      default:
        console.error("Unsupported feed content type")
        break
    }

    setPublishDisabled(disabled)
  }, [selectedType, name, text, files, selectedTest])

  useEffect(() => {
    if (!materialRef.current) return;
    if (selectedType !== FeedContentType.Material) return;
    const resizeObserver = new ResizeObserver(() => {
      const { current } = materialRef
      const boundingRect = current.getBoundingClientRect()
      const { height } = boundingRect
      setHeight(Math.round(height))
    });
    resizeObserver.observe(materialRef.current);
    return () => resizeObserver.disconnect();
  }, [materialRef, selectedType])

  useEffect(() => {
    if (!testRef.current) return;
    if (selectedType !== FeedContentType.Test) return
    const resizeObserver = new ResizeObserver(() => {
      const { current } = testRef
      const boundingRect = current.getBoundingClientRect()
      const { height } = boundingRect
      setHeight(Math.round(height))
    });
    resizeObserver.observe(testRef.current);
    return () => resizeObserver.disconnect();
  }, [testRef, selectedType])


  useEffect(() => {
    if (!exerciseRef.current) return;
    if (selectedType !== FeedContentType.Exercise) return;
    const resizeObserver = new ResizeObserver(() => {
      const { current } = exerciseRef
      const boundingRect = current.getBoundingClientRect()
      const { height } = boundingRect
      setHeight(Math.round(height))
    });
    resizeObserver.observe(exerciseRef.current);
    return () => resizeObserver.disconnect();
  }, [exerciseRef, selectedType])

  useEffect(() => {
    setSelectedLesson(null)
    setSelectedMaterialLesson(null)
    if (selectedType === FeedContentType.Test) {
      getTests()
      setPlaceholder(t("addDescription"))
    } else if (selectedType === FeedContentType.Exercise) {
      setPlaceholder(t("addDescription"))
    }
  }, [selectedType])

  useEffect(() => {
    if (publishingStatus === ButtonStatus.Success || publishingStatus === ButtonStatus.Error) {
      setPublishTimeout(
        setTimeout(() => setPublishingStatus(null), 3000)
      )
    }
  }, [publishingStatus])

  useEffect(() => {
    const getLessons = async () => {
      try {
        let lessons = await api.get(`/teacher/modules/${moduleId}/lessons`);
        lessons = lessons.map(l => {
          return {
            id: l.id,
            label: l.name
          }
        })
        lessons.unshift({ label: "Nessuna lezione", id: null })
        setLessons(lessons);
      } catch (e) {
        console.error(e);
      }
    };

    if (prefetchedLessons === null) {
      getLessons();
    }

    return () => {
      if (publishTimeout) {
        clearTimeout(publishTimeout)
      }
    }
  }, []);

  useEffect(() => {
    if (prefetchedLessons?.length > 0 && lessons?.length === 0) {
      const lessons = prefetchedLessons.map(l => ({ label: l.name, id: l.id }))
      lessons.unshift({ label: "Nessuna lezione", id: null })
      setLessons(lessons)
    }
  }, [prefetchedLessons])

  const getTests = useCallback(async () => {
    setLoadingTests(true)
    try {
      const tests = await api.get(`/teacher/modules/${moduleId}/tests?publishable=true`)
      setTests(tests)
      setFilteredTests(tests)
    } catch (e) {
      console.error(e)
    }
    setLoadingTests(false)
  }, [])

  const onTestSearch = useCallback((text) => {
    if (tests && tests.length > 0) {
      if (text) {
        const filtered = tests.filter(t => t.name.toLowerCase().replaceAll(" ", "").includes(text))
        setFilteredTests(filtered)
      } else {
        setFilteredTests(tests)
      }
    }
  }, [tests])

  const publishCommunication = useCallback(async () => {
    try {
      setPublishingStatus(ButtonStatus.Loading)
      const activity = await api.post(`/teacher/modules/${moduleId}/communication`, { message: text })
      setText('')
      setPublishingStatus(ButtonStatus.Success)
      onChange({ activity_id: activity.id, added: true })
    }
    catch (e) {
      setPublishingStatus(ButtonStatus.Error)
      console.error(e)
    }
  }, [text])

  const publishTest = useCallback(async () => {

    try {
      setPublishingStatus(ButtonStatus.Loading)
      const updateTest = {
        status: TestStatus.Public,
      }
      if (expiresAt) {
        updateTest.expires_at = new Date(expiresAt).toISOString()
      }
      if (text) {
        updateTest.message = text
      }
      const activity = await api.put(`/teacher/tests/${selectedTest.id}`, updateTest)
      setPublishingStatus(ButtonStatus.Success)
      setSelectedTest(null)
      setText("")
      setExpiresAt(null)
      setSelectedType(FeedContentType.Communication)
      onChange({ activity_id: activity.id, added: true })
    } catch (e) {
      console.error(e)
      setPublishingStatus(ButtonStatus.Error)
    }
  }, [text, selectedTest, expiresAt, onChange])

  const publishExercise = useCallback(async () => {
    try {
      setPublishingStatus(ButtonStatus.Loading)

      const lesson_id = lessons[selectedLesson]?.id !== null ? lessons[selectedLesson]?.id : null
      const expires_at = exerciseExpiresAt ? new Date(exerciseExpiresAt).toISOString() : null
      const description = text

      const newExercise = {
        name,
        description,
        expires_at,
        module_id: moduleId,
        lesson_id: lesson_id ?? null
      }
      const form = new FormData()
      for (const k in newExercise) {
        if (newExercise[k] !== null) {
          form.append(k, newExercise[k])
        }
      }

      for (let i = 0; i < exerciseFiles.length; i++) {
        form.append('files', exerciseFiles[i]);
      }
      const activity = await api.post("/teacher/exercises", form, { headers: { "Content-Type": "multipart/form-data" } })

      setPublishingStatus(ButtonStatus.Success)
      onChange({ activity_id: activity.id, added: true })

      setName("")
      setText("")
      setSelectedLesson(null)
      setExerciseExpiresAt(null)
      setExerciseFiles([])
      setSelectedType(FeedContentType.Communication)
    } catch (e) {
      console.error(e)
      setPublishingStatus(ButtonStatus.Error)
    }

  }, [name, selectedLesson, text, exerciseFiles, exerciseExpiresAt, lessons, moduleId, onChange])

  const publishMaterial = useCallback(async () => {
    try {
      setPublishingStatus(ButtonStatus.Loading)
      const form = new FormData()
      if (selectedMaterialLesson !== null && lessons[selectedMaterialLesson].id !== null) {
        form.append("lesson_id", lessons[selectedMaterialLesson].id)
      }

      if (text) {
        form.append("message", text)
      }
      form.append("module_id", moduleId)

      for (let i = 0; i < files.length; i++) {
        form.append(`files`, files[i])
      }
      const response = await api.post("teacher/materials", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      const { activity_id } = response
      onChange({ activity_id, added: true })
      setPublishingStatus(ButtonStatus.Success)

      setSelectedMaterialLesson(null)
      setText("")
      setFiles([])
      setSelectedType(FeedContentType.Communication)
    } catch (e) {
      setPublishingStatus(ButtonStatus.Error)
      console.error(e)
    }
  }, [lessons, selectedMaterialLesson, moduleId, files, onChange, text])

  const publish = useCallback(async () => {
    setPublishing(true)
    switch (selectedType) {
      case FeedContentType.Communication:
        await publishCommunication()
        break
      case FeedContentType.Material:
        await publishMaterial()
        break
      case FeedContentType.Test:
        await publishTest()
        break
      case FeedContentType.Exercise:
        await publishExercise()
        break
      default:
        console.log("TODO")
    }
    setPublishing(false)
  }, [selectedType, publishCommunication, publishTest, publishExercise, publishMaterial])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={context?.user?.picture} alt="avatar" className={styles.avatar} />
        <TextareaAutosize
          value={text}
          minRows={3} maxRows={10}
          type="text"
          className={styles.textArea}
          placeholder={placeholder}
          disabled={publishing}
          onChange={(e) => {
            const { value } = e.target
            setText(value)
          }} />
      </div>

      <Collapsable
        expanded={selectedType === FeedContentType.Material || selectedType === FeedContentType.Test || selectedType === FeedContentType.Exercise}
        expadendHeight={`${height}px`}
      >
        <div ref={materialRef} style={{ display: selectedType === FeedContentType.Material ? "flex" : "none" }}
          className={styles.materialContainer}
        >
          <DropdownSelection
            disabled={lessons.length === 0}
            placeholder={t("exercises.lesson.placeholder")}
            appereance="transparent"
            defaultOptionIndex={selectedMaterialLesson}
            options={lessons}
            onSelect={(index) => {
              if (index !== selectedMaterialLesson) {
                setSelectedMaterialLesson(index)
              }
            }} />
          {
            files.length > 0 &&
            <div className={styles.filesContainer}>
              <div className={styles.subtitle} onClick={() => {
                if (dropzoneRef && dropzoneRef.current) {
                  dropzoneRef.current.click();
                }
              }}>
                Allegati <AddIcon style={{ color: "var(--tertiary)" }} />
              </div>
              <div className={styles.files}>
                <Each
                  of={files}
                  render={(file) => (
                    <div className={styles.file}>
                      <MaterialButton
                        material={file}
                        onClick={() => {
                          setFiles((prevFiles) => {
                            const idx = prevFiles.findIndex(f => f.id === file.id)
                            if (idx > -1) prevFiles.splice(idx, 1)
                            return [...prevFiles]
                          })
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          }
          <div style={{
            display: files.length === 0 ? "flex" : "none"
          }}>
            <Dropzone
              onDrop={(newFiles) => {
                newFiles.forEach(f => f.id = v4())
                setFiles([...files, ...newFiles])
              }}>
              {({ getRootProps, getInputProps }) => (
                <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                  <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef}>
                    <input {...getInputProps()} />
                    <div className={styles.dropzone}>
                      <InsertDocumentIllustration />
                      <div className={styles.dropzoneLabel}>
                        Trascina o seleziona dei file.
                      </div>
                      <Button
                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                        accentColor={"var(--tertiary)"}
                        onClick={() => {
                          if (dropzoneRef?.current) {
                            dropzoneRef.current.click()
                          }
                        }}
                      >
                        SCEGLI FILE
                      </Button>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
        <div ref={testRef} style={{ display: selectedType === FeedContentType.Test ? "flex" : "none" }} className={styles.testContainer}>
          <div className={styles.title}>{t("tests.addTest")}</div>
          <TextInput
            type="date"
            value={expiresAt}
            placeholder={t("tests.expiresAt")}
            onKeyUp={(d) => setExpiresAt(d)}
          />
          {
            selectedTest === null &&
            <div className={styles.addTest}>
              <div>{t("tests.addTestHelperText")}</div>
              <Button
                inverse
                accentColor={"var(--tertiary)"}
                onClick={() => setOpenTestsDialog(true)}
              >
                {t("tests.selectTest").toUpperCase()}
              </Button>
            </div>
          }
          {
            selectedTest &&
            <TestCard test={selectedTest} hideMenu useVariant changeable onChange={() => setOpenTestsDialog(true)} />
          }

          <Dialog
            open={openTestsDialog}
            title={t("tests.selectTest")}
            contentMaxHeight="80vh"
            onClose={() => {
              setOpenTestsDialog(false)
            }}
            style={{ width: "90vw", height: "fit-content", maxHeight: "90vh" }}
          >
            <div className={styles.testsDialog}>
              <TextInput
                onKeyUp={onTestSearch}
                type="search"
                placeholder={t("search")}
              />
              <div className={styles.tests}>
                {
                  loadingTests &&
                  <Each
                    of={[0, 1]}
                    render={() => (
                      <div className={styles.test}>
                        <TestCard loading selectable hideMenu/>
                      </div>
                    )}
                  />
                }
                {
                  !loadingTests && filteredTests.length > 0 &&
                  <Each
                    of={filteredTests}
                    render={(test) => (
                      <div className={styles.test}>
                        <TestCard
                        hideMenu
                          test={test}
                          selectable
                          onSelect={() => {
                            setSelectedTest(test)
                            setOpenTestsDialog(false)
                          }}
                        />
                      </div>
                    )}
                  />
                }

                {
                  !loadingTests && filteredTests.length === 0 &&
                  <div className={styles.noItems}>
                    {t("tests.noTests")}
                  </div>
                }
              </div>
            </div>
          </Dialog>
        </div>
        <div ref={exerciseRef} style={{ display: selectedType === FeedContentType.Exercise ? "flex" : "none" }} className={styles.exerciseContainer}>
          <div className={styles.exerciseInputs}>
            <TextInput
              type="text"
              placeholder={t("exercises.name")}
              onKeyUp={(s) => {
                setName(s)
              }}
              value={name}
            />
            <DropdownSelection
              disabled={lessons.length === 0}
              placeholder={t("exercises.lesson.placeholder")}
              appereance="transparent"
              defaultOptionIndex={selectedLesson}
              options={lessons}
              onSelect={(index) => {
                if (index !== selectedLesson) {
                  setSelectedLesson(index)
                }
              }} />
            <TextInput
              type="date"
              value={exerciseExpiresAt}
              placeholder={t("exercises.expiresAt")}
              onKeyUp={(d) => setExerciseExpiresAt(d)}
            />
          </div>
          <div className={styles.dropzoneContainer}>
            <div className={styles.subtitle} >
              {t("exercises.materials")}
              {exerciseFiles.length > 0 &&
                <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                  if (exerciseDropzoneRef && exerciseDropzoneRef.current) {
                    exerciseDropzoneRef.current.click();
                  }
                }} />
              }
            </div>
            {
              exerciseFiles.length > 0 &&
              <div className={styles.filesContainer} style={{ marginTop: ".5rem" }}>
                <div className={styles.files}>
                  <Each
                    of={exerciseFiles}
                    render={(file) => (
                      <div className={styles.file}>
                        <MaterialButton
                          material={file}
                          onClick={() => {
                            setExerciseFiles((prevFiles) => {
                              const idx = prevFiles.findIndex(f => f.id === file.id)
                              if (idx > -1) prevFiles.splice(idx, 1)
                              return [...prevFiles]
                            })
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            }
            <div style={{
              display: exerciseFiles.length === 0 ? "flex" : "none",
            }}>
              <Dropzone
                onDrop={(newFiles) => {
                  newFiles.forEach(f => f.id = v4())
                  setExerciseFiles([...exerciseFiles, ...newFiles])
                }}>
                {({ getRootProps, getInputProps }) => (
                  <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                    <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={exerciseDropzoneRef} >
                      <input {...getInputProps()} />
                      <div className={styles.dropzone}>
                        <InsertDocumentIllustration />
                        <div className={styles.dropzoneLabel}>
                          {t("materials.dragAndDrop")}
                        </div>
                        <Button
                          style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                          accentColor={"var(--tertiary)"}
                          onClick={() => {
                            if (exerciseDropzoneRef?.current) {
                              exerciseDropzoneRef.current.click()
                            }
                          }}
                        >
                          {t("materials.selectFile").toUpperCase()}
                        </Button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
      </Collapsable >
      <div className={styles.bottom}>
        <div className={styles.contentTypes}
          style={{
            paddingLeft: (selectedType === FeedContentType.Communication) ? "48px" : 0
          }}
        >
          <Each
            of={
              [
                { id: FeedContentType.Communication, icon: CommunicationIcon, color: "var(--secondary)", backgroundColor: "var(--background-color-red" },
                { id: FeedContentType.Material, icon: MaterialIcon, color: "var(--sf-green)", backgroundColor: "var(--background-color-green" },
                { id: FeedContentType.Exercise, icon: ExerciseIcon, color: "var(--sf-yellow)", backgroundColor: "var(--background-color-yellow" },
                { id: FeedContentType.Test, icon: TestIcon, color: "var(--tertiary)", backgroundColor: "var(--background-tertiary-color" },
              ]
            }
            render={(item) => (
              <IconButton
                IconComponent={item.icon}
                color={item.color}
                backgroundColor={item.backgroundColor}
                selected={selectedType === item.id}
                onClick={() => setSelectedType(item.id)}
                enableHover
              />
            )}
          />

        </div>
        <div className={styles.action}>
          <Button onClick={publish}
            accentColor={"var(--tertiary)"}
            disabled={publishing || publishDisabled}
            status={publishingStatus}
          >
            {t(`feed.publish`).toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FeedInputCard
