
import { useCallback, useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EmptyTests } from "../assets/images/illustrations/3D/empty-test.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import TestCard from "../components/cards/TestCard"
import EmptyContent from "../components/EmptyContent.js"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import { getDefaultTest } from "../utils.js"
import Test from "./Test.js"
import styles from "./Tests.module.css"

const Tests = () => {

    const { t } = useTranslation()
    const [tests, setTests] = useState([]);
    const [filteredTests, setFilteredTests] = useState([]);
    const [modules, setModules] = useState([])
    const [testsPerModules, setTestsPerModules] = useState([]);
    const [testsLoading, setTestsLoading] = useState(false)
    const [showAddPage, setShowAddPage] = useState(false)
    const [showEditPage, setShowEditPage] = useState(false)
    const [selectedTest, setSelectedTest] = useState(getDefaultTest())

    useEffect(() => {
        const getModules = async () => {
            try {
                const lessons = await api.get("/teacher/lessons");
                const lessonsPerModule = {}

                // group lessons by moduleId
                for (const lesson of lessons) {
                    if (lesson.module.id in lessonsPerModule) {
                        lessonsPerModule[lesson.module.id].lessons.push(lesson)
                    } else {
                        lessonsPerModule[lesson.module.id] = {
                            id: lesson.module.id,
                            label: lesson.module.name,
                            lessons: [lesson]
                        }
                    }
                }
                const modules = Object.values(lessonsPerModule)
                modules.unshift({ id: null, label: t("tests.module.empty") })
                setModules(modules)
            } catch (e) {
                console.error(e);
            }
        };

        getModules()
        getTests();
    }, []);

    useEffect(() => {
        const testPerModules = {}
        for (const test of filteredTests) {
            const { module } = test;
            if (!module) {
                if (!testPerModules.noModules) {
                    testPerModules.noModules = {
                        moduleId: null,
                        moduleName: t("tests.noModuleGroup"),
                        tests: [test]
                    }
                } else {
                    testPerModules.noModules.tests.push(test)
                }
            } else if (module.id in testPerModules) {
                testPerModules[module.id].tests.push(test)
            } else {
                testPerModules[module.id] = {
                    moduleId: module.id,
                    moduleName: module.name,
                    tests: [test]
                }
            }
        }
        const tpm = Object.values(testPerModules)
        setTestsPerModules(tpm)
    }, [filteredTests])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showAddPage, showEditPage])

    const getTests = useCallback(async () => {
        setTestsLoading(true);
        try {
            const tests = await api.get("/teacher/tests");
            setTests(tests);
            setFilteredTests(tests);
        } catch (e) {
            console.error(e);
        }
        setTestsLoading(false);
    }, []);

    const onSearch = useCallback((text) => {
        if (tests && tests.length > 0) {
            if (text) {
                const filter = tests.filter((s) => {
                    const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
                    const query = text ? text.toLowerCase().replaceAll(" ", "") : text
                    return target.includes(query);
                });
                setFilteredTests(filter);
            } else {
                setFilteredTests(tests);
            }
        }
    }, [tests])

    const onDelete = useCallback(() => {
        getTests()
    }, [])

    const onComplete = useCallback(() => {
        getTests()
    }, [])

    const onEditAction = useCallback((mIdx, tIdx) => {
        const test = testsPerModules[mIdx].tests[tIdx]
        setSelectedTest({ ...test })
        setShowEditPage(true)
    }, [testsPerModules])

    const onEdit = useCallback(() => {
        setShowEditPage(false)
        getTests()
    }, [tests])

    const onAdd = useCallback(() => {
        setShowAddPage(false)
        getTests()
    }, [tests])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Test</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        {
                            showAddPage === false && showEditPage === false &&
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <div className={typo.title}>{t("tests.title")}</div>


                                    <div className={styles.add}>
                                        <Button
                                            style={{
                                                padding: "1rem 2rem",
                                            }}
                                            onClick={() => setShowAddPage(true)}
                                        >
                                            {t("tests.addNewTest").toUpperCase()}
                                            <AddIcon />
                                        </Button>
                                    </div>
                                </div>
                                <TextInput
                                    onKeyUp={onSearch}
                                    type="search"
                                    placeholder={t("search")}
                                    style={{
                                        backgroundColor: "var(--background-secondary-color)",
                                    }} />
                                <div className={styles.modules}>
                                    {
                                        testsLoading === true &&
                                        <Each
                                            of={[1]}
                                            render={() => (
                                                <Card>
                                                    <div className={styles.module}>
                                                        <div className={typo.subheadline}>
                                                            <Skeleton type="rect" width="256px" height={"22px"} borderRadius={"8px"} />
                                                        </div>
                                                        <div className={styles.tests}>
                                                            <Each
                                                                of={[1, 2, 3]}
                                                                render={() => (
                                                                    <div className={styles.test}>
                                                                        <TestCard loading />
                                                                    </div>)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                        />
                                    }

                                    {
                                        testsLoading === false && testsPerModules.length > 0 &&
                                        <Each
                                            of={testsPerModules}
                                            render={(moduleTests, moduleIndex) => (
                                                <Card>
                                                    <div className={styles.module}>
                                                        <div className={typo.subheadline}>{moduleTests.moduleName}</div>
                                                        <div className={styles.tests}>
                                                            <Each
                                                                of={moduleTests.tests.sort((a, b) => {
                                                                    if (!a.deleted_at && b.deleted_at) {
                                                                        return -1
                                                                    }
                                                                    return 1
                                                                })}
                                                                render={(test, testIndex) => (
                                                                    <div className={styles.test}>
                                                                        <TestCard test={test} onDelete={onDelete} onEdit={() => onEditAction(moduleIndex, testIndex)} onComplete={onComplete} />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                        />
                                    }

                                    {
                                        testsLoading === false && testsPerModules.length === 0 &&
                                        <EmptyContent
                                            Illustration={EmptyTests}
                                            message={"Non ci sono ancora test qui, creane uno nuovo per vederlo in lista."}
                                            imageStyle={{ width: "192px" }}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            showAddPage === true &&
                            <Test prefetchedModules={modules} onBack={() => setShowAddPage(false)} onSave={onAdd} />
                        }
                        {
                            showEditPage === true &&
                            <Test prefetchedModules={modules} onBack={() => setShowEditPage(false)} onSave={onEdit} test={selectedTest} />
                        }
                    </div>
                </div>
            </div>

        </HeaderFooterLayout>
    )
}

export default Tests
