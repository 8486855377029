import { useDaily, useDevices, useLocalSessionId, useMeetingSessionState, useMeetingState, useNetwork, useParticipantIds, useScreenShare, useWaitingParticipants } from "@daily-co/daily-react"
import { Excalidraw } from "@excalidraw/excalidraw"
import { useEffect, useState } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import api from "../../api"
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg"
import { palette } from "../../common/constants"
import { Each } from "../../common/Each"
import typo from "../../typography.module.css"
import Button from "../Button"
import Toggle from "../Toggle"
import DailyChat from "./DailyChat"
import DailyControls from "./DailyControls"
import DailyParticipant from "./DailyParticipant"
import styles from "./DailyRoom.module.css"
import DailySpotlighted from "./DailySpotlighted"
import AlertDialog from "../dialogs/AlertDialog"
import PDFCanvas from "./PDFCanvas"

const DailyRoom = ({ roomName, students, onStudentsChange, websocket, whiteboard, onWhiteboardChange, onCallRejoin }) => {

    const call = useDaily()
    const devices = useDevices()
    const localSessionId = useLocalSessionId()
    const participants = useParticipantIds({ filter: 'remote' })
    const handRaisedParticipants = useParticipantIds({ filter: (p) => { return p?.userData?.handRaised } })
    const { waitingParticipants, grantAccess } = useWaitingParticipants()
    const { data: sessionData } = useMeetingSessionState()
    const meetingState = useMeetingState()
    const fullScreenHandle = useFullScreenHandle()
    const { screens } = useScreenShare()
    const { threshold } = useNetwork()
    
    const [waitingList, setWaitingList] = useState([])
    const [spotlightedId, setSpotlightedId] = useState(null)
    const [showParticipants, setShowParticipants] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [excalidrawAPI, setExcalidrawAPI] = useState(null)
    const [chat, setChat] = useState(false)
    const [unread, setUnread] = useState(0)
    const [file, setFile] = useState(null);
    const [roomInfo, setRoomInfo] = useState(null)
    const [lockLoading, setLockLoading] = useState(false)
    const [overlayMenu, setOverlayMenu] = useState({ open: false, mode: null })
    const [whiteboardController, setWhiteboardController] = useState(null)
    const [endingCall, setEndingCall] = useState(false)
    const [muted, setMuted] = useState(false)

    useEffect(() => {
        if (devices.cameras.length > 0) {
            if (!devices.currentCam) {
                devices.setCamera(devices.cameras[0].device.deviceId)
            }
        }
        if (devices.speakers.length > 0) {
            if (!devices.currentSpeaker) {
                devices.setSpeaker(devices.speakers[0].device.deviceId)
            }
        }
        if (devices.microphones.length > 0) {
            if (!devices.currentMic) {
                devices.setMicrophone(devices.microphones[0].device.deviceId)
            }
        }
    }, [devices])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .catch(error => {
                console.error(error)
            })
    }, [])

    useEffect(() => {
        if (sessionData?.spotlighted) {
            setSpotlightedId(sessionData.spotlighted)
        }
        else if (localSessionId) {
            setSpotlightedId(localSessionId)
        }
    }, [sessionData, localSessionId])

    useEffect(() => {
        if (excalidrawAPI) {
            if (whiteboard.show && !whiteboard.readOnly) {
                websocket.sendMessage({
                    type: 'whiteboard_started',
                    data: [],
                    session_id: localSessionId
                })
                if (call) {
                    const canvas = document.querySelector('.excalidraw__canvas.static')
                    if (canvas) {
                        const stream = canvas.captureStream(60)
                        call.startCustomTrack({ track: stream.getTracks()[0], trackName: 'whiteboard' })
                    }
                }
            }

            if (!whiteboard.show && !whiteboard.readOnly) {
                websocket.sendMessage({
                    type: 'whiteboard_stopped'
                })
                if (call && !['error', 'left-meeting'].includes(meetingState)) {
                    call.stopCustomTrack('whiteboard')
                }
            }
            excalidrawAPI.updateScene({ elements: whiteboard.data })
        }

        if (whiteboard.show && whiteboard.readOnly && whiteboard.sessionId && call) {
            let participants = call.participants()
            const p = participants[whiteboard.sessionId]
            if (p) {
                setWhiteboardController(`${p.userData?.name} ${p.userData?.surname}`)
            }
        }

    }, [whiteboard])

    useEffect(() => {
        let all = call.participants()
        let newStudents = students.map(s => {
            for (let p of participants) {
                if (s.id === all[p]?.userData?.id && !all[p]?.owner) {
                    s.present = true
                }
            }
            return s
        })

        onStudentsChange(newStudents)
    }, [participants])

    useEffect(() => {
        if (waitingParticipants) {
            const wList = [...waitingParticipants.map(w => {
                return { name: w.name, sessionId: w.id }
            })]
            setWaitingList(wList)
            if (waitingParticipants.length === 0) {
                setOverlayMenu({ open: false, mode: null })
            }
        }
    }, [waitingParticipants])

    useEffect(() => {
        if (roomName) {
            getRoomInfo(roomName)
        }
    }, [roomName])

    useEffect(() => {
        if (screens && screens.find(s => s.session_id === localSessionId)) {
            setSpotlightedId(localSessionId)
        }
    }, [screens])

    useEffect(() => {
        if (['error', 'left-meeting'].includes(meetingState)) {
            onWhiteboardChange(null, null, true)
            setOverlayMenu({ open: false, mode: null })
        }
    }, [meetingState])

    const unlockAndAccept = async () => {
        await lockRoom(false)
        setRoomInfo((prev) => {
            prev.privacy = 'public'
            return { ...prev }
        })
        grantAccess("*")

    }

    const getInitials = (input) => {
        // Dividi la stringa in parole usando lo spazio come delimitatore
        const words = input.split(' ');

        // Prendi la prima lettera di ciascuna parola e uniscile
        const initials = words.map(word => word.charAt(0)).join('');

        // Restituisci la stringa di iniziali
        return initials.slice(0, 2);
    }

    const getRoomInfo = async (roomName) => {
        try {
            let roomInfo = await api.get(`/teacher/rooms/${roomName}`)
            setRoomInfo(roomInfo)
        }
        catch (e) {
            console.error(e)
        }
    }

    const lockRoom = async (locked) => {
        setLockLoading(true)
        try {
            const body = { locked: locked }
            await api.put(`teacher/rooms/${roomName}/lock`, body)
            getRoomInfo(roomName)
        }
        catch (e) {
            console.error(e)
        }
        setLockLoading(false)
    }

    const onFullScreenChange = (fullScreenState) => {
        setFullScreen(fullScreenState)
    }

    const onWhiteboardDataChange = async (event) => {
        if (!whiteboard.readOnly) {
            websocket.sendMessage({
                type: 'whiteboard_changed',
                data: event,
                session_id: localSessionId
            })
        }
    }

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange}>
            <div className={styles.container}>
                <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                    {/* --------------------------- Spotlighted Stream --------------------------- */}
                    <div className={styles.spotlighted}>
                        {!['error', 'left-meeting'].includes(meetingState) &&
                            <>
                                {spotlightedId && !whiteboard.show && !file &&
                                    <DailySpotlighted
                                        session_id={spotlightedId}
                                        isLocal={spotlightedId === localSessionId}
                                        isScreenShare={!!screens.find(s => s.session_id === spotlightedId)} />
                                }
                                {whiteboard.show && !file &&
                                    <div style={{ display: 'flex', minHeight: '500px', width: '100%', position: 'relative' }}>
                                        {whiteboardController && whiteboard.readOnly &&
                                            <div className={styles.whiteboardName}>{whiteboardController}</div>
                                        }
                                        <Excalidraw viewModeEnabled={whiteboard.readOnly} onChange={onWhiteboardDataChange} excalidrawAPI={(api) => setExcalidrawAPI(api)} />
                                    </div>
                                }
                                {file &&
                                    <PDFCanvas file={file} />
                                }

                                {sessionData?.spotlighted === spotlightedId &&
                                    <div className={styles.pinBadge}>
                                        <PinIcon />
                                        Fissato
                                    </div>
                                }

                                {['low', 'very-low'].includes(threshold) &&
                                    <div className={styles.overlay}>
                                        {threshold === 'low' &&
                                            <div>
                                                La qualità della tua connessione è scarsa...
                                            </div>
                                        }
                                        {threshold === 'very-low' &&
                                            <div>
                                                La qualità della tua connessione è molto scarsa...
                                            </div>
                                        }
                                    </div>
                                }

                            </>
                        }
                        {meetingState === 'left-meeting' &&
                            <div className={styles.meetingLeft}>
                                Hai abbandonato la lezione.
                                <Button onClick={() => {
                                    onCallRejoin()
                                }}>RIENTRA</Button>
                            </div>
                        }
                    </div>

                    {/* ---------------------------------- Chat ---------------------------------- */}
                    <DailyChat open={chat} unread={{ state: unread, setState: setUnread }} />

                    {overlayMenu.open &&
                        <>
                            {overlayMenu.mode === 'settings' &&
                                < div className={styles.settings}>
                                    <div className={styles.settingsHeader}>
                                        <div className={typo.subtitle}>Impostazioni</div>
                                    </div>
                                    <div className={typo.body} style={{ opacity: 0.7 }}>Gestisci le impostazioni della stanza</div>
                                    <div className={styles.settingsContent}>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsTitle}>ID</div>
                                            {roomInfo?.name}
                                        </div>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsTitle}>Bloccata</div>
                                            <Toggle checked={roomInfo?.privacy === 'private'} loading={lockLoading} onChange={(value) => {
                                                lockRoom(value)
                                            }} />
                                        </div>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                setEndingCall(true)
                                            }}
                                            appearance="default"
                                            inverse
                                            accentColor={'#F43A3A'}
                                            style={{ padding: '.5rem', fontSize: '1rem' }}>
                                            TERMINA LEZIONE
                                        </Button>

                                    </div>
                                </div>
                            }
                            {overlayMenu.mode === 'waiting' &&
                                <div className={styles.waiting}>
                                    <div className={styles.waitingHeader}>
                                        <div className={typo.subtitle}>In attesa</div>
                                        <div className={typo.subtitle}>{waitingList.length}</div>
                                    </div>
                                    <div className={typo.body} style={{ opacity: 0.7 }}>Gli studenti sono in attesa,<br></br>sblocca la stanza per iniziare la lezione</div>
                                    <div className={styles.waitingParticipants}>
                                        <Each of={waitingList} render={(participant, index) => {
                                            const colorIndex = (index) % palette.length
                                            const color = palette[colorIndex]
                                            return (
                                                <div className={styles.waitingParticipant}>
                                                    {participant.picture &&
                                                        <img src={participant.picture} alt="" className={styles.waitingAvatar} />
                                                    }
                                                    {!participant.picture &&
                                                        <div style={{ backgroundColor: color }} className={styles.waitingAvatar}>{getInitials(participant.name)}</div>
                                                    }
                                                    {participant.name}
                                                    {roomInfo.privacy === 'private' &&
                                                        <Button style={{ padding: '0.5rem' }} onClick={() => {
                                                            grantAccess(participant.sessionId)
                                                        }}>ACCETTA</Button>
                                                    }
                                                </div>
                                            )
                                        }} />
                                    </div>
                                    {roomInfo.privacy === 'private' &&
                                        <Button fullWidth onClick={unlockAndAccept} style={{ padding: '.5rem', fontSize: '1rem' }}>SBLOCCA</Button>
                                    }
                                </div>
                            }
                        </>
                    }

                </div>

                <div className={`${styles.participants} ${!showParticipants ? styles.close : ''}`}>
                    <div className={styles.participantsTrack}>
                        <DailyParticipant
                            isLocal={true}
                            muted={true}
                            session_id={localSessionId}
                            style={{ backgroundColor: 'black' }}
                            onClick={() => {
                                setSpotlightedId(localSessionId)
                            }} />
                        <Each of={participants} render={(p, index) => {
                            return (
                                <DailyParticipant
                                    muted={muted}
                                    session_id={p}
                                    onClick={() => {
                                        setSpotlightedId(p)
                                    }} />
                            )
                        }} />
                    </div>
                </div>

                <DailyControls
                    localSessionId={localSessionId}
                    showParticipants={showParticipants}
                    onShowParticipantsChange={() => {
                        setShowParticipants(!showParticipants)
                    }}
                    fullScreen={fullScreen}
                    onFullScreenChange={() => {
                        fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter()
                        setFullScreen(!fullScreen)
                    }}
                    whiteboard={whiteboard.show}
                    onWhiteboardChange={() => {
                        onWhiteboardChange(localSessionId, whiteboardController)
                    }}
                    chat={chat}
                    onChatChange={() => {
                        if (!chat) {
                            setUnread(0)
                        }
                        setChat(!chat)
                    }}
                    unread={unread}
                    handsRaised={handRaisedParticipants.length}
                    waitingParticipants={waitingParticipants}
                    overlayMenu={overlayMenu}
                    onOverlayMenuChange={(mode) => {
                        let newOverlayState = { open: false, mode: null }
                        if (overlayMenu.open) {
                            if (overlayMenu.mode !== mode) {
                                newOverlayState.mode = mode
                                newOverlayState.open = true
                            }
                            else {
                                newOverlayState.open = false
                            }
                        } else {
                            newOverlayState.mode = mode;
                            newOverlayState.open = true
                        }
                        setOverlayMenu(newOverlayState)
                    }}
                    pdf={file}
                    onPdfChange={(value) => {
                        setFile(value)
                    }}
                    muted={muted}
                    onMutedChange={(value) => {
                        setMuted(value)
                    }}
                />
            </div>
            <AlertDialog
                open={endingCall}
                title={`Terminare la lezione ?`}
                text={`Tutti i partecipanti saranno rimossi. Se hai sbloccato la stanza, questa sarà bloccata nuovamente.`}
                onClose={() => {
                    setEndingCall(false)
                }}
                actions={[
                    {
                        label: "Termina",
                        onClick: async () => {
                            let updateList = {};
                            for (let id in call.participants()) {
                                if (id === 'local') {
                                    continue;
                                }
                                updateList[id] = { eject: true };
                            }
                            await lockRoom(true)
                            call.updateParticipants(updateList);
                            call.leave()
                            setEndingCall(false)
                        }
                    }
                ]}
            />
        </FullScreen >
    )
}

export default DailyRoom
